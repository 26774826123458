import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard'
import UserHeaderSVG from 'src/assets/images/svg/users.svg';
import classNames from 'classnames';
import { useGetFlaggedGroups, useGetGroupMessages, useTreatGroup, useGetTreatedGroups } from '../../redux/group/hooks';
import MenuSVG from 'src/assets/images/svg/menu-white.svg';
import LoadingBar from 'src/components/loader-bar';
import Avatar from 'src/assets/images/avatar.png'

function GroupPage() {
    const [tabIndex, setTabIndex] = useState(0);
    const [groupId, setGroupId] = useState("");
    const [group, setGroup] = useState<any>(null);
    const { data: flaggedGroups, refetch: reloadFlaggedGroups, isLoading: flagLoader } = useGetFlaggedGroups();
    const { data: treatedGroups, isLoading: treatedLoader } = useGetTreatedGroups();
    const { data: groupMessages, isLoading: messageLoader } = useGetGroupMessages(groupId)
    const { mutateAsync: MarkAsThreated } = useTreatGroup();
    const [showMenu, setShowMenu] = useState(false);

    const messageLoaderTrue = !!groupId && (messageLoader);

    useEffect(() => {
        if (flaggedGroups) {
            console.log(flaggedGroups.groups.docs, 'flagged groups');
        }
    }, [flaggedGroups])


    useEffect(() => {
        if (groupMessages) {
            console.log(groupMessages.groupChats.docs, 'group messages');
        }
    }, [groupMessages])

    async function treatGroup() {
        await MarkAsThreated(groupId).then(() => {
            reloadFlaggedGroups();
            setGroupId("");
            setGroup(null);
        });
    }

    
    return (<>

        <DashboardLayout>

            <div className='flex flex-col px-3 h-full'>
                <div className='flex h-[60px] bg-secondary-2 rounded-l-[16px] flex-row items-center]'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={UserHeaderSVG} alt="" />
                        <span className='text-white font-bold ml-2'> Groups</span>
                    </div>
                </div>
                <LoadingBar loading={flagLoader || treatedLoader || messageLoaderTrue} />
                <div className='flex flex-row mt-6 h-full'>


                    {/* Left Side */}
                    <div className='flex w-[15%]  items-start flex-col' >

                        <div className='flex flex-col mt-3'>
                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': tabIndex === 0 })}
                            >
                                <span className='text-color-black title-2-semibold my-3 cursor-pointer' onClick={() => setTabIndex(0)}> Untreated Group</span>
                            </div>

                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': tabIndex === 1 })}

                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setTabIndex(1)}> Treated Group</span>

                            </div>

                        </div>


                        {/* <div className='flex flex-col mt-3'>
                            {tabs.map((item, index) => (
                                <div key={index} className='my-2 cursor-pointer' onClick={() => setTabIndex(index)}>
                                    <span className='text-color-black title-2-semibold my-3'> {item.title}</span>
                                </div>
                            ))}

                        </div> */}
                    </div>
                    <div className='w-[1px] bg-gray-400 min-h-full'>

                    </div>

                    {/* Right Side */}
                    <div className='flex w-full flex-row  pr-10'>

                        <div className='w-2/6 flex flex-col'>
                            <div className='flex h-[50px] bg-black items-center mb-3'>
                                <span className='title-2-semibold text-white px-3'>
                                    Group List
                                </span>
                            </div>

                            {tabIndex === 0 && flaggedGroups ? (
                                <>
                                    {flaggedGroups.groups.docs.map((item: any, index: number) => (
                                        <div className='flex flex-row cursor-pointer h-[50px] items-center pl-3' key={index} onClick={() => {
                                            setGroup(item);
                                            setGroupId(item._id)
                                        }} >
                                            <div className='w-[30%]'>
                                                {item.image ? (
                                                    <img src={item.image} alt="" width={'40px'} className="rounded-[40px]" />
                                                ) : (
                                                    <img src={Avatar} alt="" width={'40px'} className="rounded-[40px]" />
                                                )}
                                                
                                            </div>
                                            <div className='flex justify-center'>
                                                <span className='title-2-semibold'>
                                                    {item.name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : tabIndex === 1 && treatedGroups ? (
                                <>
                                    {treatedGroups.groups.docs.map((item: any, index: number) => (
                                        <div className='flex flex-row cursor-pointer h-[50px] items-center pl-3' key={index} onClick={() => {
                                            setGroup(item.groupId);
                                            setGroupId(item.groupId._id)
                                        }} >
                                            <div className='w-[30%]'>
                                                {item.groupId && item.groupId.image ? (
                                                    <img src={item.groupId.image} alt="" width={'40px'} className="rounded-[40px]" />
                                                )
                                                    : null}
                                            </div>
                                            <div className='flex justify-center'>
                                                <span className='title-2-semibold'>
                                                    {item.groupId.name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : null}

                        </div>
                        <div className='w-[1px] bg-gray-400 min-h-full'>
                        </div>

                        <div className='w-full'>
                            {groupMessages ? (
                                <>
                                    <div className='flex flex-col'>
                                        <div className='flex h-[50px] bg-black px-4 items-center rounded-l-md mb-3 justify-between'>
                                            <span className='text-white sub-header'>{group?.name}</span>
                                            <div className={classNames({ 'hidden': tabIndex !== 0 })}>
                                                <div
                                                    className='flex flex-col mr-4'
                                                >
                                                    <div className='cursor-pointer' onClick={() => {
                                                        setShowMenu(!showMenu);
                                                    }}>

                                                        <img src={MenuSVG} alt="" width={'15px'} />
                                                        <div className='relative block'>

                                                            <div className={classNames('absolute min-w-[160px] box-shadow z-[9999] px-3 mt-2', { block: showMenu }, { hidden: !showMenu })}>
                                                                <div className='cursor-pointer' onClick={() => {

                                                                    setShowMenu(false)
                                                                }}>
                                                                    <p className='p1 color-black-2 my-2'>Unflag Group</p>
                                                                    <hr className='divider' />
                                                                </div>

                                                                <div className='cursor-pointer' onClick={() => {
                                                                    treatGroup()
                                                                    setShowMenu(false)
                                                                }}>
                                                                    <p className='p1 color-black-2 my-2'>Treat Group</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {groupMessages.groupChats.docs.map((item: any, index: number) => (
                                            <div key={index}>
                                                {item.system ? (
                                                    <div className='text-center p1 text-color-black-2 my-2'>
                                                        <span>{item.message}</span>
                                                    </div>
                                                ) : (

                                                    <div className='rounded-lg p-3 bg-color-black w-full max-w-[300px] my-2 flex flex-col'>
                                                        <span className='p1 text-[#fc8c78] pb-2'>{item.senderId.first_name} {item.senderId.last_name}</span>
                                                        <span className='text-white p1'>{item.message}</span>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    </>
    )
}

export default GroupPage;

