import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Button from 'src/components/form/button';
import FormTextArea from 'src/components/form/textarea';
import DashboardLayout from 'src/layout/dashboard';
import { useCreateAppTopic, useGetTopics } from 'src/redux/topic/hook';
import classNames from 'classnames';
import TopicCard from 'src/components/cards/topic';
import TopicSVG from 'src/assets/images/svg/topic.svg';
import FormFileInput from 'src/components/form/file-input';
import FormSelect from 'src/components/form/select';
import SelectInterests from 'src/components/form/select-tags';
import LoadingBar from 'src/components/loader-bar';

function TopicPage() {

    const { mutateAsync: createAppTopic, isLoading: createTopicLoading } = useCreateAppTopic();
    const [param, setParam] = useState('untreated');
    const [attachmentType, setAttachmentType] = useState('image')
    const { data: topics, isLoading: topicLoading } = useGetTopics(param);
    const [interests, setInterests] = useState<string[]>([]);


    const attachmentTypeOptions = [
        { label: "Image", value: "image" },
        { label: "Video", value: "video" },
        { label: "Audio", value: "audio" },
        { label: "Document", value: "document" },
    ]

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset
    } = useForm({
        mode: 'onChange',
    });


    useEffect(() => {
        console.log(topics, 'topics')
    }, [topics])


    function submit(data: any) {
        const formData = new FormData();
        formData.append('file', data?.file[0]);
        formData.append('attachmentType', attachmentType);
        formData.append('content', DOMPurify.sanitize(data?.content));
        formData.append('tags', interests.join(','));


        createAppTopic(formData).then(() => {
            reset();
        })
    }

    return (
        <DashboardLayout>
            <div className='flex flex-col mx-3'>
                <div className='flex h-[62px] bg-secondary-2 rounded-l-[16px] flex-row items-center'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={TopicSVG} alt="" />
                        <span className='text-white font-bold ml-3'> Topics</span>
                    </div>
                </div>
                <LoadingBar loading={topicLoading} />

                <div className='flex flex-row h-full pl-3 pr-5'>
                    <div className='flex w-[15%] items-start justify-start flex-col' >
                        <div className='flex flex-col mt-3'>
                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'untreated' })}
                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('untreated')}> Untreated Topics</span>
                            </div>

                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'treated' })}
                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('treated')}> Treated  Topics</span>
                            </div>

                        </div>
                    </div>

                    <div className='w-[60%]'>
                        {topics ? (
                            <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3'>
                                {topics.map((item: any, index: number) => (
                                    <>
                                        <TopicCard topic={item} key={index} />
                                    </>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <div className='w-[25%] flex flex-col'>
                        <form onSubmit={handleSubmit(submit)}>
                            <div className='flex flex-col'>
                                <FormTextArea
                                    name='content'
                                    placeholder='Type your tot'
                                    validator='text'
                                    register={register}
                                    required={true}
                                    readOnly={createTopicLoading}
                                    error={errors.content}
                                    errorMessage={errors.content && errors.content.message}
                                />


                                <FormSelect
                                    defaultValue={attachmentType}
                                    options={attachmentTypeOptions}
                                    label={'Select Attachment Type'}
                                    onChange={(selected: string) => {
                                        setAttachmentType(selected);
                                    }}
                                    type="default"
                                />

                                <FormFileInput
                                    register={register}
                                    name="file"
                                    validator='file'
                                    required={false}
                                    readOnly={createTopicLoading}
                                    // accept='.jpg,.png,.jpeg,.gif'
                                    error={errors.file}
                                    errorMessage={errors.file && errors.file.message}
                                />

                                <span className='title-2-semibold my-4'>Select relation to this Tot</span>

                                <SelectInterests interests={interests} setInterests={setInterests} multiple={true} />

                                <Button
                                    style={{
                                        marginTop: 40,
                                    }}
                                    disabled={!isValid}
                                    type='submit'
                                    className='!rounded-[60px]'
                                    loading={createTopicLoading}
                                    color='primary'
                                >
                                    <span className='text-black'>Post </span>
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div>
                    </div>
                </div>
            </div>

        </DashboardLayout>
    )
}


export default TopicPage;