import React from 'react';
import { FormGroup, Input, InputProps } from 'reactstrap';

interface FormSwitchProps extends InputProps {
    id?: string;
    label?: string;
    className?: string;
    readOnly?: boolean;
}


const FormSwitch = ({
    id,
    label,
    className,
    readOnly,
    ...rest
}: FormSwitchProps) => {

    return (
        <div className='pb-[20px] w-full'>
            <div className='flex flex-col'>
                <FormGroup switch>
                    {label ? (
                        <label className='title pb-2' htmlFor={id}>
                            {label}
                        </label>
                    ) : null}
                    <Input
                        type="switch" role="switch"
                        className={`${className} `}
                        size={50} readOnly={readOnly}
                        {...rest}
                    />

                </FormGroup>
            </div>
        </div>
    );
};

export default FormSwitch;
