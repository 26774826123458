// CountryDropdown.js
import React from 'react';
import countries from 'src/assets/jsons/countries.json';

const CountryDropdown = ({ selectedCountry, setSelectedCountry }:{selectedCountry:string, setSelectedCountry: any}) => {
  return (
    <select
      className="border rounded p-2"
      value={selectedCountry}
      onChange={(e) => setSelectedCountry(e.target.value)}
    >
      {countries.map((country:any) => (
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      ))}
    </select>
  );
};

export default CountryDropdown;
