import React from 'react';


interface ILoadingBar {
  loading: boolean
}

function LoadingBar({ loading }: ILoadingBar) {
  return (
    <>
      {loading ? (
        <div className='loader'>
          <div className='loaderBar'></div>
        </div>
      ) : null}
    </>

  );
}

export default LoadingBar;
