import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { ShowAlert } from 'src/providers/toast';
import { useAdminInvite } from 'src/redux/user/hooks';
import { AllPermissions, Permissions } from 'src/types/permissions';
import Button from './form/button';
import FormSwitch from './form/switch';
import FormInput from './form/text-input';
import { useAdminRegister } from 'src/redux/user/hooks';

interface InviteAdminProps {
    close: () => void
}

function InviteAdmin({ close }: InviteAdminProps) {
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });

    const [permissions, setPermissions] = useState<Permissions[]>([]);
    const { mutateAsync: adminInvite, isLoading } = useAdminInvite();
    const { mutateAsync: registerAdmin } = useAdminRegister();


    const allPermissions: AllPermissions = [
        {
            name: "Dashboard Access",
            permission: "CAN_VIEW_DASHBOARD"
        },
        {
            name: "Manage Helpdesk",
            permission: "CAN_MANAGE_HELPDESK"
        },
        {
            name: "Manage Topic",
            permission: "CAN_MANAGE_TOPICS"
        },
        {
            name: "Manage Topic Comments",
            permission: "CAN_MANAGE_TOPIC_COMMENTS"
        },
        {
            name: "Manage Users",
            permission: "CAN_MANAGE_USERS"
        },
        {
            name: "Manage Groups",
            permission: "CAN_MANAGE_GROUPS"
        },
        {
            name: "Review Admin Activites",
            permission: "CAN_MANAGE_ADMIN_ACTIVITIES"
        },
        {
            name: "Manage App Admin",
            permission: "CAN_MANAGE_APP_ADMIN"
        },
        {
            name: "View Logs",
            permission: "CAN_VIEW_APP_LOGS"
        },
    ]


    async function submitForm(data: any) {
        if (permissions.length === 0) {
            ShowAlert({ type: 'info', message: "Please select at least one permission" });
            return
        }


        let payload = {
            email: DOMPurify.sanitize(data?.email),
            permissions
        }

        let payload2 = {
            email:DOMPurify.sanitize(data?.email),
            password:DOMPurify.sanitize(data?.password),
            last_name: "Account",
            first_name: "Admin",
        }

        await adminInvite(payload).then(() => {
            registerAdmin(payload2).then(()=>{
                close()
            })
        })
    }


    return (
        <div className='h-screen flex flex-col px-8'>
            <div className='mt-[20%] flex flex-col'>
                <span className='header'>Invite a new administrator</span>
                <span className='mt-2 title font-normal mb-4'>Send an invitation to your new administrator,</span>

                <form onSubmit={handleSubmit(submitForm)}>
                    <FormInput
                        type='email'
                        name='email'
                        placeholder='Email'
                        validator='email'
                        register={register}
                        required={true}
                        readOnly={isLoading}
                        error={errors.email}
                        errorMessage={errors.email && errors.email.message}
                    />
                    <FormInput
                        type='password'
                        name='password'
                        placeholder='Password'
                        validator='password'
                        register={register}
                        required={true}
                        readOnly={isLoading}
                        error={errors.password}
                        errorMessage={errors.password && errors.password.message}
                    />


                    <div className='mb-3'>
                        <span className='sub-header'> Permissions</span>
                    </div>
                    <div className='flex flex-col'>
                        {allPermissions.map((item, index) => (
                            <div className='flex flex-row justify-between' key={index}>
                                <div>
                                    <span className='text-color-gray-2 title'>{item.name}</span>
                                </div>
                                <div>
                                    <FormSwitch
                                        readOnly={isLoading}
                                        checked={permissions.includes(item.permission)}
                                        onChange={() => {
                                            if (permissions.includes(item.permission)) {
                                                setPermissions((permissions) => permissions.filter(e => e !== item.permission))
                                            }
                                            else {
                                                setPermissions([...permissions, item.permission])
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        ))}
                    </div>

                    <div className='flex justify-end'>
                        <Button
                            disabled={!isValid}
                            type='submit'
                            className='w-[35%] h-[55px]'

                            loading={isLoading}
                        >
                            <span className='text-secondary font-medium'>Register Admin with Permissions</span>
                        </Button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default InviteAdmin;