import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { tags } from 'src/api/tags';
import { useGetAppUserActivities } from 'src/redux/app-user/hooks';
import { useGetUserAnalytics } from 'src/redux/dashboard/hooks';
import { IInterest } from 'src/redux/topic/types';
import BarChart from './bar-chart';
import DonutChart from './donut-chart';
import PieChart from './pie-chart';

function UserTab() {
    const [generalUserData, setGeneralUserData] = useState<any>(null);
    const [activeUsersData, setActiveUsersData] = useState<any>(null);
    const [subscriptionData, setSubscriptionData] = useState<any>(null);
    const [usersData, setUsersData] = useState<any>(null);
    const [ageRangeData, setAgeRangeData] = useState<any>(null);
    const [genderData, setGenderData] = useState<any>(null);

    const [tagData, setTagData] = useState<any>(null);
    const [maleTagData, setMaleTagData] = useState<any>(null);
    const [femaleTagData, setFemaleTagData] = useState<any>(null);

    const { data: userAnalytics, isLoading } = useGetUserAnalytics();
    const [allInterests, setAllInterests] = useState<IInterest[]>([]);

    useEffect(() => {
        tags.getTags().then((response: any) => {
            let interestData = JSON.parse(JSON.stringify(response.data));
            console.log(interestData, '== interest')
            setAllInterests(interestData);
        });
    }, []);

    const findInterestNameById = (id: string) => {

        const name = allInterests.find((item) => {
            return item._id == id
        });

        if (!name) {
            return id;
        }

        return name?.name;
    }


    function updateAgeRangeData() {



        let data = {
            data: {
                labels: ['18 - 25', '26 - 35', '36 - 45', '46 -55', '56 - 65', 'Older Than 65'],
                datasets: [
                    {
                        cutout: '70%',
                        label: '# of users',
                        data: [
                            200, 300, 500, 100, 50, 20
                        ],
                        backgroundColor: [
                            'rgba(68, 175, 105, 1)',
                            'rgba(0, 25, 132, 1)',
                            'rgba(247, 148, 33, 1)',
                            'rgba(247, 148, 33, 1)',
                            'rgba(247, 148, 33, 1)',
                            'rgba(247, 148, 33, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: '18 - 25',
                    color: 'bg-secondary-2',
                },
                {
                    title: '26 - 35',
                    color: 'bg-[#f79421]',
                },
                {
                    title: '36 - 45',
                    color: 'bg-[#44af69]',
                },
                {
                    title: '46 - 55',
                    color: 'bg-[#44af69]',
                },
                {
                    title: '56 - 65',
                    color: 'bg-[#44af69]',
                },
                {
                    title: 'Older than 65',
                    color: 'bg-[#44af69]',
                },
            ],
            total: "75%"
        };

        setAgeRangeData(data);
    }

    function updateGeneralUserData() {
        let data = {
            data: {
                labels: ['Normal', 'Flagged', 'Suspended'],
                datasets: [
                    {

                        label: '# of Users',
                        data: [
                            userAnalytics.generalUsers.data.normalUsers,
                            userAnalytics.generalUsers.data.flaggedUsers,
                            userAnalytics.generalUsers.data.suspendedUsers
                        ],
                        backgroundColor: [
                            'rgba(103, 197, 135, 1)',
                            'rgba(234, 246, 237, 1)',
                            'rgba(201, 234, 212, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Normal',
                    color: 'bg-[#67C587]',
                },
                {
                    title: 'Flagged',
                    color: 'bg-[#EAF6ED]',
                },
                {
                    title: 'Suspended',
                    color: 'bg-[#C9EAD4]',
                },
            ],
            total: "75%"
        };

        setGeneralUserData(data);
    }

    function updateActiveUsersData() {
        let data = {
            data: {
                labels: ['Male', 'Female'],
                datasets: [
                    {

                        label: '# of Users',
                        data: [
                            userAnalytics.generalUsers.data.allActiveMaleUsers, userAnalytics.generalUsers.data.allActiveFemaleUsers
                        ],
                        backgroundColor: [
                            'rgba(253, 227, 223, 1)',
                            'rgba(202, 73, 140, 1)'
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Male',
                    color: 'bg-[#FDE3DF]',
                },
                {
                    title: 'Female',
                    color: 'bg-[#CA498C]',
                }
            ],
        };

        setActiveUsersData(data);
    }

    function updateSubscriptionData() {
        let data = {
            data: {
                labels: ['Normal', 'Premium'],
                datasets: [
                    {

                        label: '# of Users',
                        data: [
                            userAnalytics.generalUsers.data.totalUsers - userAnalytics.generalUsers.data.premiumUsers, userAnalytics.generalUsers.data.premiumUsers
                        ],
                        backgroundColor: [
                            'rgba(253, 227, 223, 1)',
                            'rgba(202, 73, 140, 1)'
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Normal',
                    color: 'bg-[#FDE3DF]',
                },
                {
                    title: 'Premium',
                    color: 'bg-[#CA498C]',
                }
            ],
        };

        setSubscriptionData(data);
    }

    function updateUsersData() {
        let data = {
            data: {
                labels: ['Male', 'Female'],
                datasets: [
                    {

                        label: '# of Users',
                        data: [
                            userAnalytics.generalUsers.data.maleUsers,
                            userAnalytics.generalUsers.data.femaleUsers
                        ],
                        backgroundColor: [
                            'rgba(253, 227, 223, 1)',
                            'rgba(202, 73, 140, 1)'
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Male',
                    color: 'bg-[#FDE3DF]',
                },
                {
                    title: 'Female',
                    color: 'bg-[#CA498C]',
                }
            ],
        };

        setUsersData(data);
    }

    function updateGenderData() {
        let data = {
            data: {
                labels: ['Male', 'Female'],
                datasets: [
                    {

                        label: '# of Users',
                        data: [
                            userAnalytics.generalUsers.maleUsers, userAnalytics.generalUsers.femaleUsers
                        ],
                        backgroundColor: [
                            'rgba(68, 175, 105, 1)',
                            'rgba(0, 25, 132, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Male',
                    color: 'bg-secondary-2',
                },
                {
                    title: 'Female',
                    color: 'bg-[#f79421]',
                },
            ],
        };

        setGenderData(data);
    }


    function updateTagData() {

        let tagsData = userAnalytics.generalUsers.data.tags.tagUsage[0];
        let tagArray: any = [];

        Object.keys(tagsData).forEach(function (key) {
            tagArray.push({
                name: findInterestNameById(key),
                value: tagsData[key]
            })
        });


        let arr = tagArray.sort(function (a: any, b: any) { return a.value - b.value; });
        arr = arr.slice(Math.max(arr.length - 10, 0)); //get the top 10 tags
        arr = arr.reverse();

        let labels: any = [];
        let dataArray: any = [];

        arr.forEach((item: any) => {
            labels.push(item.name);
            dataArray.push(item.value);
        })


        let data = {
            data: {
                labels,
                datasets: [
                    {
                        label: '# Tags',
                        data: dataArray,
                        backgroundColor: [
                            'rgba(213, 205, 252, 1)',
                            'rgba(161, 143, 252, 1)',
                        ],
                    },
                ],
            },
        };

        setTagData(data);
    }

    function updateMaleTagData() {

        let tagsData = userAnalytics.generalUsers.data.tags.maleTagUsage[0];
        let tagArray: any = [];

        Object.keys(tagsData).forEach(function (key) {
            tagArray.push({
                name: findInterestNameById(key),
                value: tagsData[key]
            })
        });


        let arr = tagArray.sort(function (a: any, b: any) { return a.value - b.value; });
        arr = arr.slice(Math.max(arr.length - 10, 0)); //get the top 10 tags
        arr = arr.reverse();

        let labels: any = [];
        let dataArray: any = [];

        arr.forEach((item: any) => {
            labels.push(item.name);
            dataArray.push(item.value);
        })


        let data = {
            data: {
                labels,
                datasets: [
                    {
                        label: '# Tags',
                        data: dataArray,
                        backgroundColor: [
                            'rgba(213, 205, 252, 1)',
                            'rgba(161, 143, 252, 1)',
                        ],
                    },
                ],
            },
        };

        setMaleTagData(data);
    }

    function updateFemaleTagData() {

        let tagsData = userAnalytics.generalUsers.data.tags.femaleTagUsage[0];
        let tagArray: any = [];

        Object.keys(tagsData).forEach(function (key) {
            tagArray.push({
                name: findInterestNameById(key),
                value: tagsData[key]
            })
        });


        let arr = tagArray.sort(function (a: any, b: any) { return a.value - b.value; });
        arr = arr.slice(Math.max(arr.length - 10, 0)); //get the top 10 tags
        arr = arr.reverse();

        let labels: any = [];
        let dataArray: any = [];

        arr.forEach((item: any) => {
            labels.push(item.name);
            dataArray.push(item.value);
        })


        let data = {
            data: {
                labels,
                datasets: [
                    {
                        label: '# Tags',
                        data: dataArray,
                        backgroundColor: [
                            'rgba(213, 205, 252, 1)',
                            'rgba(161, 143, 252, 1)',
                        ],
                    },
                ],
            },
        };

        setFemaleTagData(data);
    }






    useEffect(() => {
        console.log(userAnalytics, '= userAnaltyics');
        if (userAnalytics && allInterests) {
            updateGeneralUserData();
            updateActiveUsersData();
            updateSubscriptionData();
            updateUsersData();
            updateGenderData();
            updateAgeRangeData();
            updateTagData();
            updateMaleTagData();
            updateFemaleTagData();
        }
    }, [userAnalytics, allInterests])


    return (
        <div className='flex mt-3 flex-col'>

            {userAnalytics ? (
                <>
                    <div className='flex flex-row items-center my-3 justify-between mr-4'>
                        <span className="header">Users</span>
                        <span className='title-2 text-color-gray-6' > Last updated: {moment(userAnalytics.generalUsers.createdAt).fromNow()}</span>
                    </div>


                    {/* Section 1 */}
                    <div className='flex flex-row'>
                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3 '>
                                    <span className='p1 text-color-gray-6'> Total Number of Users</span>
                                    <span className='header-2'> {userAnalytics.generalUsers.data.totalUsers}</span>
                                </div>
                            </div>
                            <div className='w-[150px]'>
                                <PieChart data={generalUserData} />
                            </div>

                        </div>

                        <div className='box-shadow h-full flex  w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3'>
                                    <span className='p1 text-color-gray-6'> Active Users by Gender (Last 30 days)</span>
                                </div>
                            </div>
                            <div className='w-[150px]'>
                                <PieChart data={activeUsersData} />
                            </div>

                        </div>

                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col mb-3'>
                                    <span className='p1 text-color-gray-6'> Active Users by Age (Last 30 Days)</span>
                                </div>
                            </div>
                            <div className='w-[270px]'>
                                <DonutChart data={ageRangeData} />
                            </div>

                        </div>



                    </div>


                    {/* Section 2 */}
                    <div className='flex flex-row mt-3'>

                        <div className='box-shadow h-full flex justify-center mr-4 flex-col p-4 w-3/5'>
                            <div className='flex flex-row'>

                                <div className='flex flex-row mb-3 justify-evenly w-full'>
                                    <div className='flex items-start w-1/2'>
                                        <span className='p1'> Users Ratio</span>
                                    </div>

                                    <div className='flex w-1/2'>
                                        <span className='p1'> Users Subscriptions</span>
                                    </div>



                                </div>
                            </div>

                            <div className='flex flex-row justify-evenly'>
                                <div className='flex w-1/2'>
                                    <div className='w-[150px]'>
                                        <PieChart data={usersData} />
                                    </div>
                                </div>

                                <div className='flex w-1/2 flex-col'>
                                    <div className='w-[150px]'>
                                        <PieChart data={subscriptionData} />
                                    </div>
                                </div>


                            </div>

                        </div>



                        <div className='box-shadow h-full flex justify-center w-2/5 mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3 text-center'>
                                <span className='p1'>Ten Most used tags </span>
                            </div>

                            <div className='w-full'>
                                <BarChart data={tagData} />
                            </div>

                        </div>

                    </div>

                    {/* Section 3 */}
                    <div className='flex flex-row mt-3'>

                        <div className='box-shadow h-full flex justify-center w-1/2 mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3 text-center'>
                                <span className='p1'>Ten Most used tags by Male </span>
                            </div>

                            <div className='w-full'>
                                <BarChart data={maleTagData} />
                            </div>

                        </div>


                        <div className='box-shadow h-full flex justify-center w-1/2 mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3 text-center'>
                                <span className='p1'> Ten Most used tags by Female </span>
                            </div>

                            <div className='w-full'>
                                <BarChart data={femaleTagData} />
                            </div>

                        </div>

                    </div>

                </>

            ) : null}




        </div>
    )
}

export default UserTab;