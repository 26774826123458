import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import DashboardLayout from 'src/layout/dashboard';
import QuizSVG from 'src/assets/images/svg/Quiz.svg';
import LoadingBar from 'src/components/loader-bar';
import UploadContainer from 'src/components/upload-container';
import CountryDropdown from 'src/components/dropdown';
import countries from 'src/assets/jsons/countries.json';

function QuizPage() {
  const [selectedCountry, setSelectedCountry] = useState('GLO'); // Default to Global

  const getSelectedCountryName = () => {
    const country = countries.find(country => country.code === selectedCountry);
    return country ? country.name : 'Global';
  };

  return (
    <DashboardLayout>
      <div className='flex flex-col mx-3'>
        <div className='flex h-[62px] bg-secondary-2 rounded-l-[16px] flex-row items-center'>
          <div className='flex flex-row items-center px-4'>
            <img src={QuizSVG} alt='' />
            <span className='text-white font-bold ml-3'> Quiz </span>
          </div>
        </div>
      </div>
      <div className='flex ml-4 mt-4'>
        <CountryDropdown
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      </div>
      <div className='mt-[140px] flex flex-col items-center justify-center'>
        <span className='text-[24px] mb-1 font-semibold'>
          Upload Quiz Question for {getSelectedCountryName()}
        </span>
        <UploadContainer selectedCountry={selectedCountry} />
      </div>
    </DashboardLayout>
  );
}

export default QuizPage;
