import { Axios } from 'src/api/axios';

const getTags = async () => {
  const { data } = await Axios.get('/admin/tags/list');
  return data;
};

export const tags = {
  getTags,
};
