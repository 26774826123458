import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard'
import UserHeaderSVG from 'src/assets/images/svg/users.svg';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userAuthInfo } from 'src/redux/user/reducer';
import FlaggedGroups from 'src/components/groups/flagged';
import { useGetFlaggedGroups, useGetGroupMessages, useTreatGroup, useGetTreatedGroups } from '../../redux/group/hooks';
import { useParams } from 'react-router-dom';
import { useGetChatsById } from 'src/redux/app-user/hooks';

function ChatPage() {
    const [group, setGroup] = useState<any>(null);
    const { chatId } = useParams();
    const { data: groupMessages } = useGetChatsById(chatId);
    // const { data: groupMessages } = useGetGroupMessages(groupId);


    useEffect(() => {
        if (groupMessages) {
            console.log(groupMessages, 'CHAT Messages');
        }
    }, [groupMessages])

    return (<>
        <DashboardLayout>
            <div className='flex flex-col px-3 h-full'>
                <div className='flex h-[60px] bg-secondary-2 rounded-l-[16px] flex-row items-center]'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={UserHeaderSVG} alt="" />
                        <span className='text-white font-bold ml-2'> Chats</span>
                    </div>
                </div>
                <div className='flex flex-row mt-6 h-full'>

                    {/* Right Side */}
                    <div className='flex w-full flex-row  pr-10'>
                        <div className='w-2/6 flex flex-col'>
                            <div className='flex h-[50px] bg-black items-center mb-3'>
                            </div>
                        </div>
                        <div className='w-[1px] bg-gray-400 min-h-full'>
                        </div>

                        <div className='w-full'>
                            {groupMessages ? (
                                <>
                                    <div className='flex flex-col'>
                                        <div className='flex h-[50px] bg-black px-4 items-center rounded-l-md mb-3 justify-between'>
                                            <span className='text-white sub-header'>{group?.name}</span>
                                            {/* <div className={classNames({ 'hidden': tabIndex !== 0 })}>
                                                <button onClick={() => treatGroup()} className='!bg-primary p-2 rounded-md'>
                                                    <span className='p1'>Trash group</span>
                                                </button>
                                            </div> */}
                                        </div>
                                        {groupMessages.map((item: any, index: number) => (
                                            <>
                                                {item.system ? (
                                                    <div className='text-center p1 text-color-black-2 my-2'>
                                                        <span>{item.message}</span>
                                                    </div>
                                                ) : (

                                                    <div className='rounded-lg p-3 bg-color-black w-full max-w-[300px] my-2 flex flex-col'>
                                                        <span className='p1 text-[#fc8c78] pb-2'>{item.senderId.first_name} {item.senderId.last_name}</span>
                                                        <span className='text-white p1'>{item.message}</span>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                        {groupMessages.length === 0 ? (
                                            <>
                                                <div className='w-full h-screen flex justify-center items-center'>
                                                    No Messages yet
                                                </div></>
                                        ) : null}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    </>
    )
}

export default ChatPage;

