import { AdminRegisterDTO } from '../types/admin';
import { Axios } from 'src/api/axios';
import { AdminLoginDTO, AdminInviteDTO,UserUpdatePennytotDTO } from 'src/types/admin';

const login = async (data: AdminLoginDTO) => {
  return await Axios.post('/admin/account/login', data);
};

const invite = async (payload: AdminInviteDTO) => {
  const { data } = await Axios.post('/admin/account/invite', payload);
  return data;
};

const register = async (payload: AdminRegisterDTO) => {
  const { data } = await Axios.post('/admin/account/register', payload);
  return data;
};

const getAdmins = async () => {
  const { data } = await Axios.get('/admin/account/get-admins');
  return data?.admins.docs;
};

const getUserPennyTot = async (userId: string) =>{
  const { data } = await Axios.get(
    `/admin/users/get-user-pennytots/${userId}`,
  );
  return data;
}

const updateUserPennyTot = async (payload: UserUpdatePennytotDTO) =>{
  const { data } = await Axios.post(
    `/admin/users/update-user-pennytots/${payload.userId}`,{ amount: payload.amount }
  );
  return data;
}



export const user = {
  login,
  invite,
  register,
  getAdmins,
  getUserPennyTot,
  updateUserPennyTot,
};
