import React from 'react';

interface FormSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    name?: string;
    label?: string;
    className?: string;
    value?: any;
    error?: any;
    errorMessage?: any;
    required?: boolean;
    onChange?: any;
    options: {
        label: string;
        value: string;
    }[];
    extraClass?: string
}

const FormSelect = ({
    type,
    name,
    label,
    defaultValue,
    readOnly,
    options,
    error,
    onChange,
    required = false,
    extraClass,
    errorMessage,
    ...rest
}: FormSelectProps) => {
    const selectedThemeClass =
        type === 'default'
            ? 'w-full !px-[15px] text-[14px] leading-[18.23px] h-[42px] !border-2 border-primary'
            : ' bg-color-off-white !px-[20px] h-[50px]';

    return (
        <div className='pb-[20px]'>
            <div className='flex flex-col'>
                {label && (
                    <label className='text-[15px] leading-[23px] font-normal text-color-gray-2'>
                        {label}
                    </label>
                )}

                <select
                    className={`select-input rounded-md ${selectedThemeClass} ${extraClass} !border-2 !border-color-gray mt-[10px] text-color-black`}
                    onChange={(event) => {
                        onChange!(event.target.value);
                    }}
                    {...rest}
                >
                    {options.map((option: any, i: number) => (
                        <option key={i} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {error && <span>* {errorMessage}</span>}
        </div>
    );
};

export default FormSelect;
