import React from 'react';
import SideLogin from 'src/assets/images/svg/side-login.svg'

interface AuthLayoutProps {
    children: React.ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
    return (
        <React.Fragment>

            <div className='flex flex-row min-h-screen'>
                <div className=' w-1/2'>
                    {children}
                </div>

                <div className='w-1/2 flex justify-center items-center'>
                    <img src={SideLogin} alt="" width={'75%'} />
                </div>
            </div>
        </React.Fragment>

    )
}

export default AuthLayout;