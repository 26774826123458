import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard'
import Logo from 'src/assets/images/svg/logo.svg';
import UserTab from 'src/components/dashboard/user-tab';
import Tabview from 'src/components/tabview';
import TopicTab from 'src/components/dashboard/topic-tab';
import GroupTab from '../../components/dashboard/group-tab';

function DashboardPage() {
    const [activeTab, setActiveTab] = useState('1');


    const EmptyTab = () => {
        return (
            <div>
            </div>
        )
    }


    const tabs = [
        {
            title: "Users",
            Content: EmptyTab
        },
        {
            title: "Topics",
            Content: EmptyTab
        },
        {
            title: "Groups",
            Content: EmptyTab
        },
        {
            title: "Others",
            Content: EmptyTab
        }
    ];



    return (
        <DashboardLayout showAvatar={false}>
            <div className='flex flex-col m-4'>
                <div className='flex flex-row justify-between'>
                    <div className="flex items-center">
                        <img src={Logo} alt="" width={'150px'} />
                        <span className='header-2 ml-3'> Analtyics</span>
                    </div>

                    <div>
                        <div className='mt-3'>
                            <Tabview tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                        </div>
                    </div>

                </div>

                {activeTab == '1' ? (
                    <UserTab />
                ) : activeTab == '2' ? (
                    <>
                        <TopicTab />
                    </>
                ) : activeTab == '3' ? (
                    <GroupTab />
                )
                    : null}


            </div>
        </DashboardLayout >

    )
}

export default DashboardPage;


