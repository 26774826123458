import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard'
import UserHeaderSVG from 'src/assets/images/svg/users.svg';
import { Offcanvas, OffcanvasBody } from 'reactstrap';
import ViewSVG from 'src/assets/images/svg/icons/view.svg';
import ActiviteSVG from 'src/assets/images/svg/icons/activites.svg';
import DeleteSVG from 'src/assets/images/svg/icons/delete.svg';
import { useGetAppUsers, useUpdateUser } from 'src/redux/app-user/hooks';
import ViewUsersReports from 'src/components/app-users/view-user-reports';
import classNames from 'classnames';
import ViewUser from 'src/components/app-users/view-user';
import ViewUserActivities from '../../components/app-users/view-user-activites';
import LoadingBar from 'src/components/loader-bar';

function AppUserPage() {

    const [isSideOpen, setIsSideOpen] = useState(false);
    const [isShowUserDetails, setIsShowUserDetails] = useState(false);
    const [isShowUserActivities, setIsShowUserActivities] = useState(false);
    const [param, setParam] = useState('active');
    const [selectedUser, setSelectedUser] = useState(null);
    const [search, setSearch] = useState('');
    const { data: admins, isLoading } = useGetAppUsers(param, search);
    const { mutate } = useUpdateUser();


    useEffect(() => {
        if (admins) {
            console.log('admins ====', admins)
        }
    }, [admins])

    const sidebarWidth = '450px';

    const handleSidebarToggle = () => {
        setIsSideOpen(!isSideOpen);
    };

    const handleUserSidebarToggle = () => {
        setIsShowUserDetails(!isShowUserDetails);
    };

    const handleUserActivitiesToggle = () => {
        setIsShowUserActivities(!isShowUserActivities);
    };


    return (
        <>
            <DashboardLayout>
                <div>
                    {isShowUserDetails ? (

                        <Offcanvas
                            direction='end'
                            toggle={() => handleUserSidebarToggle()}
                            isOpen={isShowUserDetails}
                            style={{ width: sidebarWidth }}
                        >
                            <OffcanvasBody className='!p-0'>
                                <ViewUser close={() => setIsShowUserDetails(false)} user={selectedUser} />
                            </OffcanvasBody>
                        </Offcanvas>
                    ) : null}

                    {isShowUserActivities ? (

                        <Offcanvas
                            direction='end'
                            toggle={() => handleUserActivitiesToggle()}
                            isOpen={isShowUserActivities}
                            style={{ width: sidebarWidth }}
                        >
                            <OffcanvasBody className='!p-0'>
                                <ViewUserActivities close={() => setIsShowUserActivities(false)} user={selectedUser} />
                            </OffcanvasBody>
                        </Offcanvas>
                    ) : null}


                    {isSideOpen ? (

                        <Offcanvas
                            direction='end'
                            toggle={() => handleSidebarToggle()}
                            isOpen={isSideOpen}
                            style={{ width: sidebarWidth }}
                        >
                            <OffcanvasBody className='!p-0'>

                                <ViewUsersReports close={() => setIsSideOpen(false)} user={selectedUser} />


                            </OffcanvasBody>
                        </Offcanvas>
                    ) : null}

                </div>

                <div className='flex flex-col px-3'>
                    <div className='flex h-[60px] bg-secondary-2 rounded-l-[16px] flex-row items-center justify-between'>
                        <div className='flex flex-row items-center px-4'>
                            <img src={UserHeaderSVG} alt="" />
                            <span className='text-white font-bold ml-2'> App Users </span>
                        </div>
                        <div className="flex flex-row px-2">

                            <input type="text"
                                placeholder='Search User'
                                className='h-[35px] px-2 y-3 bg-transparent !text-primary border-b border-b-primary'
                                value={search}
                                onInput={(e: any) => setSearch(e.target.value)}
                            />

                        </div>
                    </div>
                    <LoadingBar loading={isLoading} />
                    <div className='flex flex-row mt-6'>
                        <div className='flex w-[25%]  items-start flex-col' >
                            <div className='flex flex-col mt-3'>
                                <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'active' })}
                                >
                                    <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('active')}> Active App Users</span>
                                </div>
                                <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'suspended' })}
                                >
                                    <span className='text-color-black title-2-semibold my-3 cursor-pointer' onClick={() => setParam('suspended')}> Suspended Users </span>
                                </div>

                                <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'flagged' })}
                                >
                                    <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('flagged')}> Flagged Users</span>
                                </div>

                            </div>
                        </div>

                        <div className='flex w-full flex-col pr-10'>
                            <table className='mt-8'>
                                <thead className='bg-background'>
                                    <th className='py-3 pl-4'>
                                        <span className='subtitle font-normal'> Name</span>
                                    </th>
                                    <th>
                                        <span className='subtitle font-normal'>Phone Number</span>
                                    </th>
                                    <th>
                                    </th>
                                </thead>
                                <tbody>

                                    {admins ? (<>
                                        {admins.map((item: any, index: number) => (
                                            <tr className='h-[50px]' key={index}>
                                                <td width={'25%'} className='pl-4'>
                                                    <div className='flex flex-col text-color-gray-3 title-2-semibold'>
                                                        <span className='text-inherit title-2-semibold'>{item.first_name} {item.last_name}</span>
                                                        <span className='text-inherit p1'>{item.email}</span>
                                                    </div>
                                                </td>
                                                <td width={'15%'}>
                                                    <span className='p1 text-color-gray-3'> {item.mobile_phone_number}</span>
                                                </td>
                                                <td>
                                                    <div className='flex flex-row items-center justify-between' >
                                                        <div className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => { setSelectedUser(item); setIsShowUserDetails(true) }}>
                                                            <div>
                                                                <img src={ViewSVG} alt="" />
                                                            </div>
                                                            <div className='cursor-pointer'
                                                            >
                                                                <span className='text-color-gray-3 title-2-semibold  ml-2'>  View</span>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-row items-center cursor-pointer' onClick={() => { setSelectedUser(item); setIsShowUserActivities(true) }}>
                                                            <div>
                                                                <img src={ActiviteSVG} alt="" />
                                                            </div>
                                                            <div>
                                                                <span className='text-color-gray-3 title-2-semibold  ml-2'>  Activities </span>
                                                            </div>
                                                        </div>

                                                        {param === 'active' ? (
                                                            <div className='flex flex-row items-center'>
                                                                <div>
                                                                    <img src={DeleteSVG} alt="" />
                                                                </div>


                                                                <div className='cursor-pointer' onClick={() => mutate({ userId: item._id, type: 'suspend' })}>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'>  Suspend</span>
                                                                </div>
                                                            </div>
                                                        ) : param === 'suspended' ? (
                                                            <div className='cursor-pointer' onClick={() => mutate({ userId: item._id, type: 'unuspend' })}>
                                                                <span className='text-color-gray-3 title-2-semibold  ml-2'> unsuspend</span>
                                                            </div>) :
                                                            param === 'flagged' ? (<>
                                                                <div className='cursor-pointer'>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2' onClick={() => { setSelectedUser(item); setIsSideOpen(true) }}> View Reports</span>
                                                                </div>

                                                                <div className='cursor-pointer' onClick={() => mutate({ userId: item._id, type: 'unflag' })}>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'> Unflag User</span>
                                                                </div>
                                                            </>

                                                            ) : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                    ) : null}
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>

    )
}

export default AppUserPage;

