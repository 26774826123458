import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { tags } from 'src/api/tags';
import { useGetAppUserActivities } from 'src/redux/app-user/hooks';
import { useGetGroupAnalytics } from 'src/redux/dashboard/hooks';
import { IInterest } from 'src/redux/topic/types';
import BarChart from './bar-chart';
import DonutChart from './donut-chart';
import PieChart from './pie-chart';


function GroupTab() {

    const [generalGroupData, setGeneralGroupData] = useState<any>(null);
    const [groupTagData, setGroupTagData] = useState<any>(null);

    const { data: groupAnalytics, isLoading } = useGetGroupAnalytics();
    const [allInterests, setAllInterests] = useState<IInterest[]>([]);

    useEffect(() => {
        tags.getTags().then((response: any) => {
            let interestData = JSON.parse(JSON.stringify(response.data));
            setAllInterests(interestData);
        });
    }, []);

    const findInterestNameById = (id: string) => {

        const name = allInterests.find((item) => {
            return item._id == id
        });

        if (!name) {
            return id;
        }

        return name?.name;
    }



    function updateGeneralGroupData() {
        let data = {
            data: {
                labels: ['Normal', 'Flagged'],
                datasets: [
                    {

                        label: '# of Groups',
                        data: [
                            groupAnalytics.groups.data.appropriateGroups,
                            groupAnalytics.groups.data.totalGroups - groupAnalytics.groups.data.appropriateGroups
                        ],
                        backgroundColor: [
                            'rgba(103, 197, 135, 1)',
                            'rgba(234, 246, 237, 1)',
                        ],
                    },
                ],
            },
            legends: [
                {
                    title: 'Normal',
                    color: 'bg-[#67C587]',
                },
                {
                    title: 'Flagged',
                    color: 'bg-[#EAF6ED]',
                },

            ]
        };

        setGeneralGroupData(data);
    }

    function updateGroupTagData() {

        let tagsData = groupAnalytics.groups.data.groupTagsCount;

        let tagArray: any = [];

        tagsData.forEach((item: any) => {
            tagArray.push({
                name: findInterestNameById(item._id),
                value: item.count
            })
        });




        let arr = tagArray.sort(function (a: any, b: any) { return a.value - b.value; });
        arr = arr.slice(Math.max(arr.length - 10, 0)); //get the top 10 tags
        arr = arr.reverse();

        let labels: any = [];
        let dataArray: any = [];

        arr.forEach((item: any) => {
            labels.push(item.name);
            dataArray.push(item.value);
        })


        let data = {
            data: {
                labels,
                datasets: [
                    {
                        label: '# Tags',
                        data: dataArray,
                        backgroundColor: [
                            'rgba(213, 205, 252, 1)',
                            'rgba(161, 143, 252, 1)',
                        ],
                    },
                ],
            },
        };

        console.log(data, '==== red de')

        setGroupTagData(data);
    }

    useEffect(() => {

        console.log(groupAnalytics, '= groupAnaltyics');

        if (groupAnalytics && allInterests) {
            updateGeneralGroupData();
            updateGroupTagData();
        }

    }, [groupAnalytics, allInterests])


    return (
        <div className='flex mt-3 flex-col'>

            {groupAnalytics ? (
                <>
                    <div className='flex flex-row items-center my-3 justify-between mr-4'>
                        <span className="header">Groups</span>
                        <span className='title-2 text-color-gray-6' > Last updated: {moment(groupAnalytics.groups.createdAt).fromNow()}</span>
                    </div>


                    {/* Section 1 */}

                    <div className='flex flex-row'>
                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3 '>
                                    <span className='p1 text-color-gray-6'> Total Number of Topics</span>
                                    <span className='header-2'> {groupAnalytics.groups.data.totalGroups}</span>
                                </div>
                            </div>
                            <div className='w-[150px]'>
                                <PieChart data={generalGroupData} />
                            </div>

                        </div>

                        <div className='box-shadow h-full flex w-full mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3 text-center'>
                                <span className='p1'>Ten Most used tags </span>
                            </div>


                            <div className='w-full'>
                                <BarChart data={groupTagData} />
                            </div>



                        </div>



                    </div>

                    {/* Section 2 */}

                    <div className='flex flex-row mt-3'>

                    </div>


                    {/* Section 3 */}

                    <div className='flex flex-row mt-3'>

                        <div className='box-shadow h-full flex w-1/2 mr-4 flex-col p-4'>
                            <div className='flex flex-row'>

                                <div className='flex flex-col mb-3 '>
                                    <span className='p1 text-color-gray-6'> Total Number of Active Groups (Last 30 Days)</span>

                                </div>
                            </div>


                        </div>

                        <div className='box-shadow h-full flex w-1/2 mr-4 flex-col p-4'>

                            <div className='flex flex-col mb-3'>
                                <span className='p1 text-color-gray-6'> Groups with most chats  </span>
                            </div>
                        </div>





                    </div>

                </>

            ) : null}

        </div>
    )
}

export default GroupTab;