import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import FormInput from 'src/components/form/text-input';
import AuthLayout from 'src/layout/auth';
import Logo from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/form/button';
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminRegister } from 'src/redux/user/hooks';

function AdminRegistrationPage() {
    const { mutateAsync: registerAdmin, isLoading } = useAdminRegister();
    const navigate = useNavigate();

    const { email } = useParams();

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });


    async function submitForm(data: any) {
        if (email) {
            let payload = {
                email: email,
                first_name: DOMPurify.sanitize(data?.first_name),
                last_name: DOMPurify.sanitize(data?.last_name),
                password: DOMPurify.sanitize(data?.password)
            }

            await registerAdmin(payload).then(() => {
                navigate('/login');
            })
        }
    }


    return (
        <AuthLayout>
            <div className='flex flex-col items-center  justify-center h-full'>
                <div className='w-full max-w-[400px] flex-col'>
                    <div className="flex">
                        <img src={Logo} alt="" />
                    </div>
                    <div className='flex mt-2  mb-10'>
                        <span>Complete your registration</span>
                    </div>

                    <form onSubmit={handleSubmit(submitForm)}>
                        <FormInput
                            label='First Name'
                            type='text'
                            name='first_name'
                            validator='text'
                            register={register}
                            required={true}
                            readOnly={isLoading}
                            error={errors.first_name}
                            errorMessage={errors.first_name && errors.first_name.message}
                        />

                        <FormInput
                            label='Last Name'
                            type='text'
                            name='last_name'
                            validator='text'
                            register={register}
                            required={true}
                            readOnly={isLoading}
                            error={errors.last_name}
                            errorMessage={errors.last_name && errors.last_name.message}
                        />

                        <FormInput
                            label='Password'
                            type='password'
                            name='password'
                            validator='password'
                            register={register}
                            required={true}
                            readOnly={isLoading}
                            error={errors.password}
                            errorMessage={errors.password && errors.password.message}
                        />

                        <Button
                            disabled={!isValid}
                            type='submit'
                            loading={isLoading}
                            color='secondary'
                            className='mt-4'
                        >
                            <span className='text-white'>Register </span>
                        </Button>
                    </form>
                </div>

            </div>

        </AuthLayout>
    )
}

export default AdminRegistrationPage;