import { useMutation, useQuery } from '@tanstack/react-query';
import { helpdesk } from 'src/api/helpdesk';
import { ShowAlert } from 'src/providers/toast';
import { ReplyHelpdeskMessageDTO } from 'src/types/helpdesk';
import { queryClient } from './../../index';

export function useGetHelpDeskMessages(helpdeskId: string) {
  return useQuery(
    ['helpdesk-messages', { helpdeskId }],
    () => {
      return helpdesk.getHelpDeskMessages(helpdeskId);
    },
    {
      enabled: helpdeskId !== '',
    },
  );
}

export function useGetHelpdeskCategories() {
  return useQuery(['helpdesk-categories'], helpdesk.getHelpDeskCategories, {
    select: (data) => {
      let newData: any = [{ label: 'All Categories', value: '' }];

      data.forEach((item: any) => {
        newData.push({ label: item, value: item });
      });

      return newData;
    },
    cacheTime: 86400000, //1 day
    staleTime: 86400000, // 1 day
  });
}

export function useGetHelpDeskTickets(
  search: string,
  category: string,
  closed: boolean,
) {
  return useQuery(['helpdesk-tickets', { search, category, closed }], () => {
    return helpdesk.getHelpdeskTickets({ search, category, closed });
  });
}

export function useReplyHelpdeskMessage() {
  return useMutation(
    (payload: ReplyHelpdeskMessageDTO) => {
      return helpdesk.replyHelpdeskMessage(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries(['helpdesk-messages']);
        ShowAlert({ type: 'success', message: response.message });
      },
    },
  );
}
