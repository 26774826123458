import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard'
import UserHeaderSVG from 'src/assets/images/svg/users.svg';
import { Offcanvas, OffcanvasBody } from 'reactstrap';
import InviteAdmin from 'src/components/invite-admin';
import ViewSVG from 'src/assets/images/svg/icons/view.svg';
import ActiviteSVG from 'src/assets/images/svg/icons/activites.svg';
import EditSVG from 'src/assets/images/svg/icons/edit.svg';
import DeleteSVG from 'src/assets/images/svg/icons/delete.svg';
import { useGetAdmins } from 'src/redux/user/hooks';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userAuthInfo } from '../../../redux/user/reducer';
import LoadingBar from 'src/components/loader-bar';

function AdminPage() {

    const [isSideOpen, setIsSideOpen] = useState(false);
    const { data: admins, isLoading } = useGetAdmins();
    const [param, setParam] = useState('all');
    const user = useSelector(userAuthInfo);

    useEffect(() => {
        if (admins) {
            console.log('admins ====', admins)
        }
    }, [admins])



    const sidebarWidth = '450px';

    const handleSidebarToggle = () => {
        setIsSideOpen(!isSideOpen);
    };

    return (<>

        <DashboardLayout>
            <div>
                <Offcanvas
                    direction='end'
                    toggle={() => handleSidebarToggle()}
                    isOpen={isSideOpen}
                    style={{ width: sidebarWidth }}
                >
                    <OffcanvasBody className='!p-0'>
                        <InviteAdmin close={() => setIsSideOpen(false)} />
                    </OffcanvasBody>
                </Offcanvas>
            </div>

            <div className='flex flex-col px-3'>
                <div className='flex h-[60px] bg-secondary-2 rounded-l-[16px] flex-row items-center]'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={UserHeaderSVG} alt="" />
                        <span className='text-white font-bold ml-2'> Administrator </span>
                    </div>
                </div>
                <LoadingBar loading={isLoading} />
                <div className='flex flex-row mt-6'>
                    <div className='flex w-[25%]  items-start flex-col' >
                        <div className='flex flex-col mt-3'>
                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'all' })}

                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('all')}> All Admins</span>
                            </div>
                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'pending' })}

                            >
                                <span className='text-color-black title-2-semibold my-3 cursor-pointer' onClick={() => setParam('pending')}>Pending Invites</span>
                            </div>

                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'suspended' })}

                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('suspended')}> Suspended Admins</span>

                            </div>
                        </div>
                    </div>
                    <div className='flex w-full flex-col pr-10'>
                        <div>
                            {user.permissions.includes('CAN_INVITE_ADMIN') || user.super_admin === true ? (
                                <button className='!bg-primary p-3 rounded-lg' onClick={() => handleSidebarToggle()}>
                                    <span className='title-2'> Invite new admin </span>
                                </button>
                            ) : null}


                        </div>

                        {(admins && user.permissions.includes('CAN_MANAGE_APP_ADMIN')) || user.super_admin === true ? (
                            <table className='mt-8'>

                                <thead className='bg-background'>
                                    <th className='py-3 pl-4'>
                                        <span className='subtitle font-normal'> Name</span>
                                    </th>
                                    <th>
                                        <span className='subtitle font-normal'>Last Active</span>
                                    </th>
                                    <th>

                                    </th>
                                </thead>
                                <tbody>
                                    {admins?.map((item: any, index: number) => (
                                        <>
                                            {param === 'all' ? (
                                                <tr className='h-[50px]' key={index}>
                                                    <td width={'25%'} className='pl-4'>
                                                        <div className='flex flex-col text-color-gray-3 title-2-semibold'>
                                                            <span className='text-inherit title-2-semibold'>{item.first_name} {item.last_name}</span>
                                                            <span className='text-inherit p1'>{item.email}</span>
                                                        </div>
                                                    </td>
                                                    <td width={'15%'}>
                                                        <span className='p1 text-color-gray-3'> Some hours ago </span>
                                                    </td>
                                                    <td>
                                                        <div className='flex flex-row items-center justify-between'>
                                                            <div className='flex flex-row items-center'>
                                                                <div className=''>
                                                                    <div className={classNames('rounded-xl p-[5px] ', {
                                                                        'bg-[#36B071]': item.first_name
                                                                    },
                                                                        {
                                                                            'bg-color-red': !item.first_name
                                                                        }
                                                                    )}
                                                                    ></div>
                                                                </div>

                                                                <div className='ml-2'>
                                                                    <span className='text-color-gray-3 title-2-semibold'>{item.first_name ? 'Active' : 'Pending'}</span>
                                                                </div>
                                                            </div>

                                                            <div className='flex flex-row items-center'>
                                                                <div>
                                                                    <img src={ViewSVG} alt="" />
                                                                </div>
                                                                <div>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'>  View</span>
                                                                </div>
                                                            </div>


                                                            <div className='flex flex-row items-center'>
                                                                <div>
                                                                    <img src={ActiviteSVG} alt="" />
                                                                </div>
                                                                <div>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'>  Activites</span>
                                                                </div>
                                                            </div>


                                                            <div className='flex flex-row items-center'>
                                                                <div>
                                                                    <img src={EditSVG} alt="" />
                                                                </div>
                                                                <div>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'>  Edit</span>
                                                                </div>
                                                            </div>


                                                            <div className='flex flex-row items-center'>
                                                                <div>
                                                                    <img src={DeleteSVG} alt="" />
                                                                </div>
                                                                <div>
                                                                    <span className='text-color-gray-3 title-2-semibold  ml-2'>  Suspend</span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : param === 'pending' ? (
                                                <>
                                                    {!item.first_name ? (
                                                        <tr className='h-[50px]' key={index}>
                                                            <td width={'25%'} className='pl-4'>
                                                                <div className='flex flex-col text-color-gray-3 title-2-semibold'>
                                                                    <span className='text-inherit title-2-semibold'>{item.first_name} {item.last_name}</span>
                                                                    <span className='text-inherit p1'>{item.email}</span>
                                                                </div>
                                                            </td>
                                                            <td width={'15%'}>
                                                                <span className='p1 text-color-gray-3'> Some hours ago </span>
                                                            </td>
                                                            <td>
                                                                <div className='flex flex-row items-center justify-between'>
                                                                    <div className='flex flex-row items-center'>
                                                                        <div className=''>
                                                                            <div className={classNames('rounded-xl p-[5px] ', {
                                                                                'bg-[#36B071]': item.first_name
                                                                            },
                                                                                {
                                                                                    'bg-color-red': !item.first_name
                                                                                }
                                                                            )}
                                                                            ></div>
                                                                        </div>

                                                                        <div className='ml-2'>
                                                                            <span className='text-color-gray-3 title-2-semibold'>{item.first_name ? 'Active' : 'Pending'}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-row items-center'>
                                                                        <div>
                                                                            <img src={ViewSVG} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-color-gray-3 title-2-semibold  ml-2'>  View</span>
                                                                        </div>
                                                                    </div>


                                                                    <div className='flex flex-row items-center'>
                                                                        <div>
                                                                            <img src={ActiviteSVG} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-color-gray-3 title-2-semibold  ml-2'>  Activites</span>
                                                                        </div>
                                                                    </div>


                                                                    <div className='flex flex-row items-center'>
                                                                        <div>
                                                                            <img src={EditSVG} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-color-gray-3 title-2-semibold  ml-2'>  Edit</span>
                                                                        </div>
                                                                    </div>


                                                                    <div className='flex flex-row items-center'>
                                                                        <div>
                                                                            <img src={DeleteSVG} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-color-gray-3 title-2-semibold  ml-2'>  Suspend</span>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>
                                            ) : param === 'suspended' ? (<>
                                                {item.suspended ? (
                                                    <tr className='h-[50px]' key={index}>
                                                        <td width={'25%'} className='pl-4'>
                                                            <div className='flex flex-col text-color-gray-3 title-2-semibold'>
                                                                <span className='text-inherit title-2-semibold'>{item.first_name} {item.last_name}</span>
                                                                <span className='text-inherit p1'>{item.email}</span>
                                                            </div>
                                                        </td>
                                                        <td width={'15%'}>
                                                            <span className='p1 text-color-gray-3'> Some hours ago </span>
                                                        </td>
                                                        <td>
                                                            <div className='flex flex-row items-center justify-between'>
                                                                <div className='flex flex-row items-center'>
                                                                    <div className=''>
                                                                        <div className={classNames('rounded-xl p-[5px] ', {
                                                                            'bg-[#36B071]': item.first_name
                                                                        },
                                                                            {
                                                                                'bg-color-red': !item.first_name
                                                                            }
                                                                        )}
                                                                        ></div>
                                                                    </div>

                                                                    <div className='ml-2'>
                                                                        <span className='text-color-gray-3 title-2-semibold'>{item.first_name ? 'Active' : 'Pending'}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex flex-row items-center'>
                                                                    <div>
                                                                        <img src={ViewSVG} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-color-gray-3 title-2-semibold  ml-2'>  View</span>
                                                                    </div>
                                                                </div>


                                                                <div className='flex flex-row items-center'>
                                                                    <div>
                                                                        <img src={ActiviteSVG} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-color-gray-3 title-2-semibold  ml-2'>  Activites</span>
                                                                    </div>
                                                                </div>


                                                                <div className='flex flex-row items-center'>
                                                                    <div>
                                                                        <img src={EditSVG} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-color-gray-3 title-2-semibold  ml-2'>  Edit</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex flex-row items-center'>
                                                                    <div>
                                                                        <img src={DeleteSVG} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-color-gray-3 title-2-semibold  ml-2'>  Unsuspend</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </>

                                            ) : null}


                                        </>

                                    ))}

                                </tbody>
                            </table>
                        ) : null}

                    </div>
                </div>
            </div>
        </DashboardLayout>
    </>

    )
}

export default AdminPage;

