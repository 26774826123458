import React, { useEffect, useState } from 'react'
import CommentSVG from 'src/assets/images/svg/topic-comments.svg'
import ShareSVG from 'src/assets/images/svg/share.svg'
import LikedSVG from 'src/assets/images/svg/liked.svg'
import { useUpdateTopic } from 'src/redux/topic/hook';
import MenuSVG from 'src/assets/images/svg/menu.svg';
import classNames from 'classnames';


function TopicCard({ topic, showCommentsAndLikes = true }: any) {

    const { mutate: updateTopic } = useUpdateTopic();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        console.log(topic, ' = topic');
    }, [topic]);



    return (
        <div className='flex flex-row'>

            <div className='w-[15%]'>
                <div className='h-[30px] w-[30px]'>
                    {topic.contentId && topic.contentId?.user.profile_picture ? (
                        <img src={topic.contentId.user.profile_picture} width="30px" className='rounded-[30px] h-[30px]' alt="" />
                    ) : null}
                </div>
            </div>

            <div className='flex flex-col w-[80%]'>
                <div className='flex flex-row mt-1 justify-between'>
                    <span className='title-2-semibold'>{topic.contentId?.user.first_name} {topic.contentId?.user.last_name}</span>
                    <div
                        className='flex flex-col'
                    >
                        <div className='cursor-pointer' onClick={() => {
                            setShowMenu(!showMenu);
                        }}>

                            <img src={MenuSVG} alt="" width={'15px'} />
                            <div className='relative block'>

                                <div className={classNames('absolute min-w-[160px] box-shadow z-[1000] px-3 mt-2', { block: showMenu }, { hidden: !showMenu })}>
                                    <div className='cursor-pointer' onClick={() => {
                                        updateTopic({ contentId: topic._id, appropriate: true })
                                        setShowMenu(false)
                                    }}>
                                        <p className='p1 color-black-2 my-2'>Treat as appropriate</p>
                                        <hr className='divider' />
                                    </div>

                                    <div className='cursor-pointer' onClick={() => {
                                        updateTopic({ contentId: topic._id, treated: true, appropriate: false })
                                        setShowMenu(false)
                                    }}>
                                        <p className='p1 color-black-2 my-2'>Treat as inappropriate</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <span className='p1 color-black-2 mt-1'>{topic.timeAgo}</span>

                <span className='mt-3 p1' >
                    {topic.contentId?.content}
                </span>

                <div className='mt-3 max-h-[350px]'>
                    {topic.contentId?.image ? (
                        <img src={topic.contentId.image} width={'100%'} className='rounded-md' alt='' />
                    ) : null}

                    {topic.contentId?.video ? (
                        <video width="100%" height={'300px'} controls>
                            <source src={topic.contentId.video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    ) : null}

                    {topic.contentId?.audio ? (
                        <audio controls>
                            <source src={topic.contentId.audio} />
                            Your browser does not support HTML video.
                        </audio>
                    ) : null}

                    {topic.contentId?.document ? (
                        <div className='flex flex-row justify-between bg-color-gray p-2 rounded-md items-center'>
                            <span className='p1'> Document File: </span>
                            <a href={topic.contentId.document} target="_blank" rel="noreferrer"> <span className='p1'>Download </span>
                            </a></div>

                    ) : null}
                </div>


                {showCommentsAndLikes ? (
                    <div className='flex flex-row justify-between mt-3'>

                        <img src={CommentSVG} alt="" width={'20px'} />

                        <img src={ShareSVG} alt="" width={'20px'} />

                        <img src={LikedSVG} alt="" width={'20px'} />

                    </div>
                ) : null}


                {topic && topic.adminId && topic.treated ? (
                    <div className='flex flex-row justify-between mt-3'>
                        <span className='p2'> Status : {topic.appropriate ? 'appropriate' : 'inappropriate'} </span>

                        <span className='p2'> Treated by: {topic.adminId.first_name} {topic.adminId.last_name}</span>
                    </div>
                ) : null}


            </div>


        </div>
    )
}


export default TopicCard;