import classNames from 'classnames';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';


interface TabViewProps {
    tabs: {
        title: string;
        Content?: React.FunctionComponent;
    }[];
    activeTab: string;
    setActiveTab: any;
}

function Tabview({ tabs, activeTab, setActiveTab }: TabViewProps) {


    return (
        <div className='w-full'>
            <Nav tabs className='w-full flex'>
                {tabs.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink
                            to={''}
                            className={classNames('w-full flex h-[40px] items-end  px-4', { '!border-b-[3px] !border-b-primary font-semibold': activeTab === (index + 1).toString() })}
                            onClick={() => { setActiveTab((index + 1).toString()) }}
                        >
                            <span className='mb-2'> {item.title}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab} className='mt-3'>
                {tabs.map(({ title, Content }, index) => (
                    <TabPane key={index} tabId={(index + 1).toString()}>
                        {Content ? (
                            <Content />
                        ) : null}

                    </TabPane>
                ))}
            </TabContent>
        </div>
    )
}

export default Tabview;
