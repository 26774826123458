import React, {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';

import { tags } from 'src/api/tags';
import { IInterest } from 'src/redux/topic/types';


type SelectInterestsProps =
    | {
        multiple: boolean;
        setInterests: any;
        interests: string[];
        interest?: string;
        setInterest?: any;
    }
    | {
        multiple?: never;
        setInterests?: any;
        interests?: string[];
        interest: string;
        setInterest: any;
    };

interface IInterestButtonProps {
    item: IInterest;
}

const SelectInterests: FunctionComponent<SelectInterestsProps> = ({
    setInterests,
    interests,
    setInterest,
    interest,
    multiple,
}) => {
    const [allInterests, setAllInterests] = useState<IInterest[]>([]);

    useEffect(() => {
        tags.getTags().then((response: any) => {
            let interestData = JSON.parse(JSON.stringify(response.data));
            setAllInterests(interestData);
        });
    }, []);

    const toggleInterest = (id: string) => {
        if (multiple) {
            if (isInterestSelected(id)) {
                setInterests(interests!.filter((item) => item !== id));
            } else {
                setInterests((interests: string[]) => [...interests, id]);
            }
        } else {
            if (isInterestSelected(id)) {
                setInterest('');
            } else {
                setInterest(id);
            }
        }
    };

    const isInterestSelected = (id: string): boolean => {
        if (multiple) {
            const getIndex = interests!.findIndex((x: string) => x === id);
            return getIndex === -1 ? false : true;
        } else {
            return id === interest ? true : false;
        }
    };

    const InterestButton: FunctionComponent<IInterestButtonProps> = ({
        item,
    }): JSX.Element => {
        return (
            <div
                onClick={() => toggleInterest(item._id)}
                className='px-3 flex w-full cursor-pointer'
                style={{
                    backgroundColor: isInterestSelected(item._id) ? 'black' : 'white',
                    height: 35,
                    marginTop: 1,
                    marginBottom: 20,
                    borderRadius: 30,
                    padding: 7,
                    //  paddingHorizontal: 20,
                    borderWidth: 1,
                    borderColor: 'black',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 3,
                }}
            >
                <span
                    style={{
                        color: isInterestSelected(item._id) ? 'white' : 'black',
                        textAlign: 'center'
                    }}
                    className='text-[11px]'
                //  textType={isInterestSelected(item._id) ? 'bold' : 'regular'}
                >
                    {item.name}
                </span>
            </div>
        );
    };

    return (
        <div className='flex w-full'>

            <div
                className='flex flex-col overflow-auto'
            >
                <div
                    className='flex flex-row'
                >
                    {allInterests.map((item: IInterest, index) =>
                        (index + 1) % 5 === 0 ? <InterestButton item={item} /> : null,
                    )}
                </div>

                <div
                    className='flex flex-row'
                >
                    {allInterests.map((item: IInterest, index) =>
                        (index + 2) % 5 === 0 ? <InterestButton item={item} /> : null,
                    )}
                </div>

                <div
                    className='flex flex-row'
                >
                    {allInterests.map((item: IInterest, index) =>
                        (index + 3) % 5 === 0 ? <InterestButton item={item} /> : null,
                    )}
                </div>

                <div
                    className='flex flex-row'
                >
                    {allInterests.map((item: IInterest, index) =>
                        (index + 4) % 5 === 0 ? <InterestButton item={item} /> : null,
                    )}
                </div>

                <div
                    className='flex flex-row'
                >
                    {allInterests.map((item: IInterest, index) =>
                        (index + 5) % 5 === 0 ? <InterestButton item={item} /> : null,
                    )}
                </div>
            </div>

        </div>
    );
};

export default SelectInterests;
