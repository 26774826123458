import { Axios } from './axios';

const getUsers = async (payload: any) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/admin/users/get-users?type=${payload.type}${search}`,
  );
  return data?.users.docs;
};

const updateUser = async (payload: any) => {
  const { data } = await Axios.post(
    `/admin/users/update/${payload.userId}?type=${payload.type}`,
  );
  return data;
};

const getUserFlaggedReports = async (userId: string) => {
  const { data } = await Axios.get(
    '/admin/users/get-user-flagged-reports/' + userId,
  );
  return data?.flaggedReports.docs;
};

const getChatById = async (chatId: string) => {
  const { data } = await Axios.get('/admin/users/get-user-chats/' + chatId);
  return data?.chats.docs;
};

const getUserActivities = async (payload: any) => {
  const { data } = await Axios.get(
    `/admin/users/get-user-contents/${payload.userId}?contentType=${payload.contentType}`,
  );
  return data;
};

export const appUsers = {
  getUsers,
  updateUser,
  getUserFlaggedReports,
  getChatById,
  getUserActivities,
};
