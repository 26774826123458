import axios from 'axios';
import { store } from '../redux';
import { ShowAlert } from 'src/providers/toast';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  timeout: 50000, // 50 seconds timeout
  headers: {},
});

Axios.interceptors.request.use(function (config: any) {
  const token = store.getState().user?.token;
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = 'An unexpected error occurred';

    if (error.response && error.response.data) {
      // Server responded with a status code outside the 2xx range
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
      // Request was made but no response was received (e.g., network errors)
      errorMessage = error.message || errorMessage;
    } else {
      // Something happened in setting up the request that triggered an error
      errorMessage = error.message || errorMessage;
    }

    // Display the error message using the ShowAlert component
    ShowAlert({ type: 'error', message: errorMessage });

    console.log(error);
    // You might want to return a more controlled error object here
    return Promise.reject(error);
  },
);

const { get, post, put, delete: destroy } = Axios;
export { get, post, put, destroy };
