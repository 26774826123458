
import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard';
import { useGetTopicComments, } from 'src/redux/topic/hook';
import classNames from 'classnames';
import TopicCommentCard from 'src/components/cards/topic-comment';
import ChatSVG from 'src/assets/images/svg/chat.svg';
import LoadingBar from 'src/components/loader-bar';

function TopicCommentPage() {

    const [param, setParam] = useState('untreated');
    const { data: topicComments, isLoading } = useGetTopicComments(param);



    useEffect(() => {
        console.log(topicComments, 'topic comments')
    }, [topicComments])


    return (
        <DashboardLayout>
            <div className='flex flex-col mx-3'>
                <div className='flex h-[82px] bg-secondary-2 rounded-l-[16px] flex-row items-center'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={ChatSVG} alt="" />
                        <span className='text-white font-bold ml-3'> Topic Comments</span>
                    </div>
                </div>
                <LoadingBar loading={isLoading} />
                <div className='flex flex-row h-full pl-3 pr-5 mt-4'>
                    <div className='flex w-[15%] items-start justify-start flex-col' >
                        <div className='flex flex-col mt-3'>
                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'untreated' })}
                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('untreated')}> Untreated Comments </span>
                            </div>

                            <div className={classNames(' flex items-end mt-2 pr-2', { 'border-b-4 border-b-primary': param === 'treated' })}
                            >
                                <span className='text-color-black title-2-semibold my-2 cursor-pointer' onClick={() => setParam('treated')}> Treated Comments</span>
                            </div>

                        </div>
                    </div>

                    <div className='w-[85%] mt-4 pl-4'>
                        {topicComments ? (
                            <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                                {topicComments.map((item: any, index: number) => (
                                    <>
                                        <TopicCommentCard topicComment={item} key={index} />
                                    </>
                                ))}
                            </div>
                        ) : null}
                    </div>


                    <div>
                    </div>
                </div>
            </div>

        </DashboardLayout>
    )
}


export default TopicCommentPage;