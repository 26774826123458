import { Axios } from 'src/api/axios';

const getFlaggedGroups = async () => {
  const { data } = await Axios.get('/admin/groups/flagged-groups');
  return data;
};

const getTreatedGroups = async () => {
  const { data } = await Axios.get('/admin/groups/treated-groups');
  return data;
};

const getGroupMessages = async (groupId: string) => {
  const { data } = await Axios.get(
    '/admin/groups/get-group-messages/' + groupId,
  );
  return data;
};

const treatGroup = async (groupId: string) => {
  const { data } = await Axios.post('/admin/groups/treat-group/' + groupId);
  return data;
};

export const group = {
  getFlaggedGroups,
  treatGroup,
  getGroupMessages,
  getTreatedGroups,
};
