import { queryClient } from 'src';
import { useMutation, useQuery } from '@tanstack/react-query';

import { appUsers } from 'src/api/app-users';
import { ShowAlert } from 'src/providers/toast';

export function useGetAppUsers(type: string, search: string) {
  return useQuery(['app-users', { type, search }], () =>
    appUsers.getUsers({ type, search }),
  );
}

export function useUpdateUser() {
  return useMutation(
    (payload: any) => {
      return appUsers.updateUser(payload);
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['app-users']);
        ShowAlert({ type: 'success', message: 'Action was successful' });
      },
    },
  );
}

export function useGetUserFlaggedReports(userId: string) {
  return useQuery(
    ['app-user-flagged-reports', { userId }],
    () => appUsers.getUserFlaggedReports(userId),
    {
      enabled: userId !== '',
    },
  );
}

export function useGetChatsById(chatId: any) {
  return useQuery(
    ['user-chats', { chatId }],
    () => appUsers.getChatById(chatId),
    {
      enabled: chatId !== null,
    },
  );
}

export function useGetAppUserActivities(userId: string, contentType: string) {
  let enabled = userId && contentType ? true : false;

  return useQuery(
    ['user-activities', { userId, contentType }],
    () => appUsers.getUserActivities({ userId, contentType }),
    {
      enabled,
    },
  );
}
