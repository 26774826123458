import { AdminRegisterDTO } from './../../types/admin';
import { AdminInviteDTO, AdminLoginDTO,UserUpdatePennytotDTO } from 'src/types/admin';
import { clearUserAuth, setUser } from 'src/redux/user/reducer';
import { user } from 'src/api';
import { store } from 'src/redux';
import { Axios } from 'src/api/axios';
import { queryClient } from 'src';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ShowAlert } from 'src/providers/toast';

export function useAdminLogin() {
  return useMutation(
    (payload: AdminLoginDTO) => {
      return user.login(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        ShowAlert({ type: 'success', message: 'Sign In success' });

        Axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response.data.token.token}`;

        //set Users
        store.dispatch(
          setUser({
            userId: response.data._id,
            authInfo: response.data,
            isLoggedIn: true,
            token: response.data.token,
          }),
        );
      },
    },
  );
}

export function useAdminInvite() {
  return useMutation(
    (payload: AdminInviteDTO) => {
      return user.invite(payload);
    },
    {
      onSuccess: (data, variables, context) => {
        ShowAlert({ type: 'success', message: data.message });
      },
    },
  );
}

export function useAdminRegister() {
  return useMutation(
    (payload: AdminRegisterDTO) => {
      return user.register(payload);
    },
    {
      onSuccess: (data, variables, context) => {
        ShowAlert({ type: 'success', message: data.message });
      },
    },
  );
}

export function useGetAdmins() {
  return useQuery(['admins'], user.getAdmins);
}

export function useGetUserPennyTot(userId:any) {
  return useQuery(
    ['userPennytot', { userId }],
    () => user.getUserPennyTot(userId),
    {
      enabled: userId !== null,
    },
  );
}

export function useUpdateUserPennyTot() {
  return useMutation(
    (payload: UserUpdatePennytotDTO) => {
      return user.updateUserPennyTot(payload);
    },
    {
      onSuccess: (data, variables, context) => {
        ShowAlert({ type: 'success', message: data.message });
        queryClient.invalidateQueries(['userPennytot', { userId: variables.userId }])
      },
    }
  )
}



export const logout = () => {
  //clear all saved cache for user
  store.dispatch(clearUserAuth()); // clear user auth from redux

  //wait for route switch before clearing cache to prevent api calls
  setTimeout(() => {
    queryClient.invalidateQueries();
    delete Axios.defaults.headers.common['Authorization'];
  }, 1000);

  ShowAlert({ type: 'success', message: 'Log out successfully' });
};
