
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetUserFlaggedReports } from '../../redux/app-user/hooks';


interface ViewUsersReportsProps {
    close: () => void;
    user: any;
}


function ViewUsersReports({ close, user }: ViewUsersReportsProps) {
    const [userId, setUserId] = useState('')
    const { data: reports, isLoading } = useGetUserFlaggedReports(userId);
    const navigate = useNavigate();

    useEffect(() => {

        if (user && user._id) {
            setUserId(user._id)
        }
    }, [user])


    useEffect(() => {
        if (reports) {
            console.log('reports', reports)
        }
    }, [reports])

    return (
        <React.Fragment>
            {user && reports ? (
                <div className='h-screen flex flex-col px-8'>
                    <div className='mt-[20%] flex flex-col'>
                        <span className='header'>User Report</span>
                        <span className='mt-2 title font-normal mb-4'>A list of flagged reports for {user.first_name} {user.last_name}</span>
                        <div className='flex'>

                            {reports.map((item: any, index: number) => (
                                <div className='flex flex-row my-2 cursor-pointer' key={index} onClick={() => { navigate(`/dashboard/user/chat/${item.reporterId._id}`) }}>
                                    <div className='flex items-center justify-center pr-2'>
                                        {item.reporterId.profile_picture ? (
                                            <img src={item.reporterId.profile_picture} alt="" width={'30px'} className='rounded-[30px] h-[30px]' />
                                        ) : null}

                                    </div>
                                    <div className='flex flex-col justify-center'>

                                        <span className='p1 font-medium'> <span className='font-bold'> {item.reporterId.first_name + " " + item.reporterId.last_name} </span> reported user for <span className='italic font-bold'> Fraud</span>  </span>
                                    </div>
                                </div>


                            ))}
                        </div>

                    </div>
                </div>
            ) : null}

        </React.Fragment>

    )
}

export default ViewUsersReports;