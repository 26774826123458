import { Axios } from 'src/api/axios';
import { ICreateTopicDTO } from 'src/redux/topic/types';

const getTopics = async (type: string) => {
  const { data } = await Axios.get('/admin/topics/get-topics?type='+ type);
  return data.topics.docs;
};

const updateTopic = async (
  contentId: string,
  appropriate: boolean,
) => {
  const { data } = await Axios.get(
    `/admin/topics/update-topic/${contentId}?appropriate=${appropriate}`,
  );

  return data;
};

const createAppTopic = async (payload: ICreateTopicDTO) => {
  const { data } = await Axios.post('/admin/topics/create-app-topic', payload);
  return data;
};


const getTopicComments = async (type: string) => {
  const { data } = await Axios.get('/admin/topics/get-topic-comments?type='+ type);
  return data.topicComments.docs;
};


const updateTopicComment = async (
  contentId: string,
  appropriate: boolean,
) => {
  const { data } = await Axios.get(
    `/admin/topics/update-topic-comment/${contentId}?appropriate=${appropriate}`,
  );

  return data;
};

export const topic = {
  getTopics,
  updateTopic,
  createAppTopic,
  getTopicComments,
  updateTopicComment
};
