
import React, { useEffect, useState } from 'react'
import { useGetAppUserActivities } from '../../redux/app-user/hooks';
import Tabview from 'src/components/tabview';
import CommentSVG from 'src/assets/images/svg/topic-comments.svg'
import ShareSVG from 'src/assets/images/svg/share.svg'
import LikedSVG from 'src/assets/images/svg/liked.svg'


interface ViewUserActivitiesProps {
    close: () => void;
    user: any;
}


function ViewUserActivites({ close, user }: ViewUserActivitiesProps) {
    const [userId, setUserId] = useState('')
    const [contentType, setContentType] = useState('topics');
    const [activeTab, setActiveTab] = useState('1');

    const { data: userContents, isLoading } = useGetAppUserActivities(
        userId,
        contentType,
    );

    useEffect(() => {
        if (user && user._id) {
            setUserId(user._id)
        }
    }, [user]);


    useEffect(() => {
        if (activeTab == '1') {
            setContentType('topics')
        }
        if (activeTab == '2') {
            setContentType('groups')
        }
        if (activeTab == '3') {
            setContentType('topic-comments')
        }

    }, [activeTab])



    useEffect(() => {
        console.log(userContents, 'userContents');
    }, [userContents])



    const TopicsTab = () => {

        return (
            <>
                <div className='flex flex-col overflow-auto'>
                    {userContents && userContents.topics && userContents.topics.docs ? (
                        <>
                            {userContents.topics.docs.map((item: any, index: number) => (
                                <div className='flex flex-row mb-3'>
                                    <div className='w-[15%]'>
                                        <div className='h-[30px] w-[30px]'>
                                            {item.userId.profile_picture ? (
                                                <img src={item.userId.profile_picture} width="30px" className='rounded-[30px] h-[30px]' alt="" />
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='flex flex-col w-[80%]'>
                                        <div className='flex flex-row mt-1 justify-between'>
                                            <span className='title-2-semibold'>{item.userId.first_name} {item.userId.last_name}</span>
                                        </div>
                                        <span className='p1 color-black-2 mt-1'>2 days ago</span>

                                        <span className='mt-3 p1' >
                                            {item.content}
                                        </span>

                                        <div className='mt-3'>
                                            {item.image ? (
                                                <img src={item.image} width={'100%'} className='rounded-md' alt='' />
                                            ) : null}

                                            {item.video ? (
                                                <video width="100%" height={'300px'} controls>
                                                    <source src={item.video} type="video/mp4" />
                                                    Your browser does not support HTML video.
                                                </video>
                                            ) : null}

                                            {item.audio ? (
                                                <audio controls>
                                                    <source src={item.audio} />
                                                    Your browser does not support HTML video.
                                                </audio>
                                            ) : null}

                                            {item.document ? (
                                                <div className='flex flex-row justify-between bg-color-gray p-2 rounded-md items-center'>
                                                    <span className='p1'> Document File: </span>
                                                    <a href={item.document} target="_blank" rel="noreferrer"> <span className='p1'>Download </span>
                                                    </a></div>

                                            ) : null}
                                        </div>


                                        <div className='flex flex-row justify-between mt-3'>
                                            <img src={CommentSVG} alt="" width={'20px'} />

                                            <img src={ShareSVG} alt="" width={'20px'} />

                                            <img src={LikedSVG} alt="" width={'20px'} />

                                        </div>

                                    </div>


                                </div>
                            ))}
                        </>
                    ) : null}
                </div>

            </>

        )
    }

    const GroupsTab = () => {
        return (
            <>
                <div className='flex flex-col overflow-auto'>
                    {userContents && userContents.groups && userContents.groups.docs ? (
                        <>
                            {userContents.groups.docs.map((item: any, index: number) => (
                                <div className='flex flex-row mb-3'>
                                    <div className='flex flex-row h-[50px] items-center pl-3 w-full' key={index}>
                                        <div className='w-[30%]'>
                                            <img src={item.image} alt="" width={'40px'} className="rounded-[40px]" />
                                        </div>
                                        <div className='flex ml-4 justify-start'>
                                            <span className='title-2-semibold'>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null}
                </div>
            </>

        )
    }



    const TopicsCommentTab = () => {
        return (
            <>
                <div className='flex flex-col overflow-auto'>
                    {user && userContents && userContents.comments && userContents.comments.docs ? (
                        <>
                            {userContents.comments.docs.map((item: any, index: number) => (
                                <div className='flex flex-row mb-3'>
                                    <div className='w-[15%]'>
                                        <div className='h-[30px] w-[30px]'>
                                            {user.profile_picture ? (
                                                <img src={user.profile_picture} width="30px" className='rounded-[30px] h-[30px]' alt="" />
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='flex flex-col w-[80%]'>
                                        <div className='flex flex-row mt-1 justify-between'>
                                            <span className='title-2-semibold'>{user.first_name} {user.last_name}</span>


                                        </div>
                                        <span className='p1 color-black-2 mt-1'>2 days ago</span>

                                        <span className='mt-3 p1' >
                                            {item.comment}
                                        </span>


                                    </div>


                                </div>
                            ))}
                        </>
                    ) : null}
                </div>
            </>

        )
    }



    const tabs = [
        {
            title: "Topics",
            Content: TopicsTab
        },
        {
            title: "Groups",
            Content: GroupsTab
        },
        {
            title: "Comments",
            Content: TopicsCommentTab
        },

    ];

    return (
        <React.Fragment>
            {user ? (
                <div className='h-screen flex flex-col px-8'>
                    <div className='mt-[20%] flex flex-col'>
                        <span className='header'>{user.first_name} {user.last_name}'s Activities</span>

                    </div>

                    <div className='mt-5'>
                        <Tabview tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </div>

                </div>
            ) : null}

        </React.Fragment>

    )
}

export default ViewUserActivites;