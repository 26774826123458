import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const DonutChart = ({ data }: any) => {
    ChartJS.register(ArcElement, Tooltip);


    return (
        <>
            {data ? (
                <div className='flex w-full h-full flex-row '>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <Doughnut data={data.data} />
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                top: '50%',
                                left: 0,
                                textAlign: 'center',
                                marginTop: '-15px',
                                lineHeight: '20px',
                            }}
                        >
                            <span className='header'>{data.total}</span>
                        </div>
                    </div>
                    <div className='flex flex-col ml-4 justify-center'>
                        {data.legends.map((item: any, index: number) => (
                            <div className='flex flex-row items-center py-1'>
                                <div
                                    className={`w-[12px] h-[12px] ${item.color}`}
                                ></div>
                                <span className='pl-1 p2'> {item.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default DonutChart;
