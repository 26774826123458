import React from 'react';
import { FormValidationTypeProps, formValidator } from '../../providers/formValidator';

interface FormFIleInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  validator?: FormValidationTypeProps;
  className?: string;
  value?: any;
  error?: any;
  errorMessage?: any;
  register?: any;
  required?: boolean;
}


const FormFileInput = ({
  name,
  readOnly,
  error,
  value,
  errorMessage,
  register,
  required = false,
  validator,
  ...rest
}: FormFIleInputProps) => {
  return (
    <>
      <div className='pb-[20px]'>
        <div className='flex flex-col'>
          <input
            type='file'
            defaultValue={value}
            className='file-input bg-color-off-white rounded-md h-[65px] p-[20px] border-[0.5px] border-color-gray mt-[10px] text-color-black'
            {...register(name, formValidator[validator!](required))}
            {...rest}
          />
          {error && <span className='text-color-error text-[13px]'>* {errorMessage}</span>}
        </div>
      </div>

    </>
  );
};

export default FormFileInput;
