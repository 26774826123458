import { ICreateTopicDTO } from './types';
import { queryClient } from './../../index';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ShowAlert } from 'src/providers/toast';
import { quiz } from '../../api/quiz';


export function useUploadCsv() {
  return useMutation(
    (file: File) => {
      // Check if the file is a CSV
      if (file.type !== 'text/csv') {
        // If not, show an error toast and throw an error
        throw new Error('Invalid file type. Please upload a CSV file.');
      }

      // If the file is a CSV, proceed with the upload
      return quiz.uploadQuestions(file);
    },
    {
      onSuccess: (data) => {
        // Perform any actions after successful upload
        ShowAlert({ type: 'success', message: 'CSV uploaded successfully' });
      },
      onError: (error:any) => {
        const message = error?.message === 'Invalid file type. Please upload a CSV file.'
        ? 'Please upload a CSV file'
        : `${error?.data?.message || error?.message}`;
      ShowAlert({ type: 'error', message });
    
      },
    }
  );
}

export function useUploadCsvByCountry() {
  return useMutation(
    ({ file, country }: { file: File; country: string }) => {
      // Check if the file is a CSV
      if (file.type !== 'text/csv') {
        // If not, show an error toast and throw an error
        throw new Error('Invalid file type. Please upload a CSV file.');
      }

      // If the file is a CSV, proceed with the upload
      return quiz.uploadQuestionsByCountry(file,country);
    },
    {
      onSuccess: (data) => {
        // Perform any actions after successful upload
        ShowAlert({ type: 'success', message: 'CSV uploaded successfully' });
      },
      onError: (error:any) => {
        const message = error?.message === 'Invalid file type. Please upload a CSV file.'
        ? 'Please upload a CSV file'
        : `${error?.data?.message || error?.message}`;
      ShowAlert({ type: 'error', message });
    
      },
    }
  );
}