import { Axios } from 'src/api/axios';
import {
  GetHelpdeskTicketsDTO,
  ReplyHelpdeskMessageDTO,
} from 'src/types/helpdesk';

// const getHelpDeskMessages = async () => {
//   const { data } = await Axios.get('/admin/helpdesk/get-helpdesk-messages');
//   return data.messages.docs;
// };

const getHelpdeskTickets = async (payload: GetHelpdeskTicketsDTO) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let category = payload.category ? '&category=' + payload.category : '';
  let closed = payload.closed ? '&closed=' + payload.closed : '';

  const { data } = await Axios.get(
    `/admin/helpdesk/get-tickets?limit=100${search}${category}${closed}`,
  );
  return data.tickets.docs;
};

const getHelpDeskMessages = async (helpdeskId: string) => {
  const { data } = await Axios.get(
    '/admin/helpdesk/get-messages/' + helpdeskId,
  );
  return data.messages.docs;
};

const replyHelpdeskMessage = async (payload: ReplyHelpdeskMessageDTO) => {
  const { data } = await Axios.post(
    '/admin/helpdesk/reply-message/' + payload.helpdeskId,
    payload.data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return data;
};

const getHelpDeskCategories = async () => {
  const { data } = await Axios.get('/admin/helpdesk/categories');
  return data?.helpdeskCategories;
};

export const helpdesk = {
  getHelpDeskMessages,
  getHelpdeskTickets,
  getHelpDeskCategories,
  replyHelpdeskMessage,
};
