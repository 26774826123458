import React from 'react'
import { useForm } from 'react-hook-form';
import FormInput from 'src/components/form/text-input';
import AuthLayout from 'src/layout/auth';
import Logo from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/form/button';
import { useAdminLogin } from 'src/redux/user/hooks';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const { mutateAsync: signIn, isLoading } = useAdminLogin();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });


    async function submitForm(data: any) {

        let payload = {
            email: DOMPurify.sanitize(data?.email),
            password: DOMPurify.sanitize(data?.password)
        }

        await signIn(payload).then((result) => {
            navigate('/dashboard');
        })

    }


    return (
        <AuthLayout>
            <div className='flex flex-col items-center  justify-center h-full'>
                <div className='w-full max-w-[400px] flex-col'>
                    <div className="flex mb-10">
                        <img src={Logo} alt="" />
                    </div>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <FormInput
                            label='Email'
                            type='email'
                            name='email'
                            validator='email'
                            register={register}
                            required={true}
                            readOnly={isLoading}
                            error={errors.email}
                            errorMessage={errors.email && errors.email.message}
                        />



                        <FormInput
                            label='Password'
                            type='password'
                            name='password'
                            validator='password'
                            register={register}
                            required={true}
                            readOnly={isLoading}
                            error={errors.password}
                            errorMessage={errors.password && errors.password.message}
                        />

                        <Button
                            disabled={!isValid}
                            type='submit'
                            loading={isLoading}
                            color='secondary'
                        >
                            <span className='text-white'>Login </span>
                        </Button>
                    </form>
                </div>

            </div>

        </AuthLayout>
    )
}

export default LoginPage;