import React, { useEffect, useState } from 'react'
import { useUpdateTopicComment } from 'src/redux/topic/hook';
import MenuSVG from 'src/assets/images/svg/menu.svg';
import classNames from 'classnames';


function TopicCommentCard({ topicComment }: any) {

    const { mutate: updateTopicComment } = useUpdateTopicComment();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        console.log(topicComment, ' = topicComment');
    }, [topicComment]);


    return (
        <div className='flex flex-row'>

            <div className='w-[15%]'>
                <div className='h-[30px] w-[30px]'>
                    {topicComment.contentId && topicComment.contentId.user.profile_picture ? (
                        <img src={topicComment.contentId.user.profile_picture} width="30px" className='rounded-[30px] h-[30px]' alt="" />
                    ) : null}
                </div>
            </div>

            <div className='flex flex-col w-[80%]'>
                <div className='flex flex-row mt-1 justify-between'>
                    <span className='title-2-semibold'>{topicComment.contentId.user.first_name} {topicComment.contentId.user.last_name}</span>
                    <div
                        className='flex flex-col'
                    >
                        <div className='cursor-pointer' onClick={() => {
                            setShowMenu(!showMenu);
                        }}>

                            <img src={MenuSVG} alt="" width={'15px'} />
                            <div className='relative block'>

                                <div className={classNames('absolute min-w-[160px] box-shadow z-[1000] px-3 mt-2', { block: showMenu }, { hidden: !showMenu })}>
                                    <div className='cursor-pointer' onClick={() => {
                                        updateTopicComment({ contentId: topicComment._id, treated: false, appropriate: true })
                                        setShowMenu(false)
                                    }}>
                                        <p className='p1 color-black-2 my-2'>Treat as appropriate</p>
                                        <hr className='divider' />
                                    </div>

                                    <div className='cursor-pointer' onClick={() => {
                                        updateTopicComment({ contentId: topicComment._id, treated: true, appropriate: false })
                                        setShowMenu(false)
                                    }}>
                                        <p className='p1 color-black-2 my-2'>Treat as inappropriate</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <span className='p1 color-black-2 mt-1'>2 days ago</span>

                <span className='mt-3 p1' >
                    {topicComment.contentId.comment}
                </span>


                {topicComment && topicComment.adminId && topicComment.treated ? (
                    <div className='flex flex-row justify-between mt-3'>
                        <span className='p2'> Status : {topicComment.appropriate ? 'appropriate' : 'inappropriate'} </span>

                        <span className='p2'> Treated by: {topicComment.adminId.first_name} {topicComment.adminId.last_name}</span>
                    </div>
                ) : null}

            </div>


        </div>
    )
}


export default TopicCommentCard;