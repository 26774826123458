import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { userAuthInfo, userToken } from 'src/redux/user/reducer';
import { ProtectedRoute } from 'src/routes/protected';
import LoginPage from './pages/guests/login';
import DashboardPage from 'src/pages/dashboard';
import AdminPage from 'src/pages/dashboard/admins';
import AdminRegistrationPage from 'src/pages/guests/admin-registration';
import GroupPage from './pages/group/index';
import AppUserPage from './pages/users/index';
import ChatPage from './pages/chats';
import TopicPage from './pages/topics';
import HelpDeskPage from './pages/helpdesk/index';
import TopicCommentsPage from './pages/topic-comments/index';
import QuizPage from './pages/quiz/index';

function App() {
  const user = useSelector(userAuthInfo);
  const token = useSelector(userToken);

  useEffect(() => {
    console.log('token', token);
    console.log('admin auth', user);

  }, [])


  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={user ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
          <Route path="/dashboard" element={<ProtectedRoute />}>
            <Route index element={<DashboardPage />} />
            <Route path='admins' element={<AdminPage />} />
            <Route path='users' element={<AppUserPage />} />
            <Route path='topics' element={<TopicPage />} />
            <Route path='quiz' element={<QuizPage />} />
            <Route path='topic-comments' element={<TopicCommentsPage />} />
            <Route path='groups' element={<GroupPage />} />
            <Route path='helpdesk' element={<HelpDeskPage />} />
            <Route path='user/chat/:chatId' element={<ChatPage />} />
          </Route>

          {/* <AdminRoutes /> */}

          <Route path='/login' element={<LoginPage />} />
          <Route path='/admin-registration/:email' element={<AdminRegistrationPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
