import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data }: any) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement
    );

    return (
        <>
            {data ? (
                <div className='flex w-full h-full flex-row '>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <Bar data={data.data} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default BarChart;
