
import React, { useEffect, useState } from 'react'
import DefaultHeaderImage from 'src/assets/images/svg/default-header.svg';
import { useGetUserPennyTot,useUpdateUserPennyTot } from 'src/redux/user/hooks';
import Avatar from 'src/assets/images/avatar.png'
import { ShowAlert } from 'src/providers/toast';

interface ViewUsersReportsProps {
    close: () => void;
    user: any;
}


function ViewUser({ close, user }: ViewUsersReportsProps) {
    const [Amount, setAmount] = useState("")
    const [inputError, setInputError] = useState("");
    const { data: userPennyTot} = useGetUserPennyTot(user._id);
    const { mutateAsync: updatePennyTot,isLoading } = useUpdateUserPennyTot();
    const userAmount = userPennyTot?.credit?.amount
   

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
          setAmount(inputValue);
          setInputError("");
        } else {
          setInputError("Input must contain only digits");
        }
      };

      async function submitForm(event: React.FormEvent<HTMLButtonElement>) {
        event.preventDefault()
        if (Amount) {
            let payload = {
              userId: user._id,
              amount: Number(Amount),
            };
            await updatePennyTot(payload).then(() => {
              setAmount("");
            });
          }else {
            setAmount(""); // Clear the input field when Amount is empty
          }

        
    }

    useEffect(() => {
        console.log(user, 'userrr')
    }, [user])



    return (
        <React.Fragment>
            {user ? (
                <div className='h-screen flex flex-col px-8'>
                    <div className='mt-[20%] flex flex-col'>
                        <div className='h-[200px] w-full'>
                            {
                                user.header_image ? (
                                    <img src={user.header_image} alt="default header" className='h-full w-full rounded-[30px]' />
                                ) : (
                                    <img src={DefaultHeaderImage} alt="default header" className='h-full w-full' />
                                )}

                        </div>
                        <div className='-mt-[90px] ml-2 '>
                            {user.profile_picture ? (
                                <img src={user.profile_picture} alt="default header" className='h-[80px] w-[80px] rounded-[80px] border-4 border-white' />
                            ) : (
                                <img src={Avatar} alt="default header" className='h-[80px] w-[80px] rounded-[80px] border-4 border-white' />
                            )}

                        </div>
                        <div className='mt-4 flex flex-col'>
                            <span className='header'>{user.first_name} {user.last_name}</span>
                            <div>
                                <span className='p1'><span className='font-bold'>Bio: </span>  {user.bio}</span>
                            </div>
                            <div>
                                <span className='p1'><span className='font-bold'>Website: </span>  {user.website}</span>
                            </div>
                            <div>
                                <span className='p1'><span className='font-bold'>LinkedIn: </span>  {user.LinkedIn}</span>
                            </div>
                            <div>
                                <span className='p1'><span className='font-bold'>Facebook: </span>  {user.facebook}</span>
                            </div>
                            <div className='flex flex-row justify-between mt-1'>
                                <span className='p1'><span className='font-bold'>Company: </span>  {user.company}</span>
                                <span className='p1'><span className='font-bold'>Position: </span>  {user.company_position}</span>
                            </div>
                        </div>



                    </div>
                    <div className='mt-[10%] flex flex-col gap-2'>
                        <span className='text-[#4F4F4F] header'>Gift Pennytot</span>
                        <span className='text-[#4F4F4F] text-[16px] font-normal'>Pennytot Balance <span>{userAmount}</span></span>
                        <form>
                            <div className='mt-[1%] flex flex-col w-full'>
                                <span className=''>Input Amount</span>
                                <input onChange={onChange} className='border-2 border-color-gray p-1 h-[50px] border-r-4 mb-2' type="text" placeholder='' value={Amount}/>
                                <button onClick={submitForm}  className='bg-[#FED830] h-[46px] hover:bg-[#caa800] active:bg-[#FED830] box-shadow ' >Send Pennytot</button>
                            </div>
                        </form>
                        
                        

                    </div>
                </div>
            ) : null}

        </React.Fragment>

    )
}

export default ViewUser;