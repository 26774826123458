import { Axios } from 'src/api/axios';

const featureSummary = async () => {
  const { data } = await Axios.get('/admin/dashboard');
  return data;
};

const usersAnalytics = async () => {
  const { data } = await Axios.get('/admin/dashboard/user-analytics');
  return data;
};

const topicAnalytics = async () => {
  const { data } = await Axios.get('/admin/dashboard/topic-analytics');
  return data;
};

const groupAnalytics = async () => {
  const { data } = await Axios.get('/admin/dashboard/group-analytics');
  return data;
};

export const dashboard = {
  featureSummary,
  usersAnalytics,
  topicAnalytics,
  groupAnalytics,
};
