import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import DashboardSVG from 'src/assets/images/svg/dashboard.svg';
import TopicSVG from 'src/assets/images/svg/topic.svg';
import ChatSVG from 'src/assets/images/svg/chat.svg';
import UserSVG from 'src/assets/images/svg/sidebar-user.svg';
import HelpdeskSVG from 'src/assets/images/svg/sidebar-helpdesk.svg';
import LogoutSVG from 'src/assets/images/svg/logout.svg';
import QuizSVG from 'src/assets/images/svg/Quiz.svg';
import { logout } from 'src/redux/user/hooks';
import Avatar from 'src/assets/images/avatar.png'
import NotificationSVG from 'src/assets/images/svg/notification.svg'
import { Permissions } from 'src/types/permissions';
import { useSelector } from 'react-redux';
import { userAuthInfo } from 'src/redux/user/reducer';

type NavProps = {
    title: string;
    route: string;
    icon: string;
    permission: Permissions;

}[]

function DashboardLayout({ children, showAvatar = true }: any) {

    const user = useSelector(userAuthInfo);

    const nav: NavProps = [
        {
            title: "Dashboard",
            route: '/dashboard',
            icon: DashboardSVG,
            permission: "CAN_VIEW_DASHBOARD"
        },
        {
            title: "Topics",
            route: '/dashboard/topics',
            icon: TopicSVG,
            permission: "CAN_MANAGE_TOPICS"
        },
        {
            title: "Comments",
            route: '/dashboard/topic-comments',
            icon: ChatSVG,
            permission: "CAN_MANAGE_TOPIC_COMMENTS"
        },
        // {
        //     title: "Chats",
        //     route: '/dashboard/chats',
        //     icon: ChatSVG,
        //     permission: "CAN_MANAGE_TOPIC"
        // },
        {
            title: "Groups",
            route: '/dashboard/groups',
            icon: ChatSVG,
            permission: "CAN_MANAGE_GROUPS"
        },
        {
            title: "Quiz",
            route: '/dashboard/quiz',
            icon: QuizSVG,
            permission: "CAN_MANAGE_GROUPS"
        },
        {
            title: "Users",
            route: '/dashboard/users',
            icon: UserSVG,
            permission: "CAN_MANAGE_USERS"
        },
        {
            title: "Admins",
            route: '/dashboard/admins',
            icon: UserSVG,
            permission: "CAN_MANAGE_APP_ADMIN"
        },
        {
            title: "Help Desk",
            route: '/dashboard/helpdesk',
            icon: HelpdeskSVG,
            permission: "CAN_MANAGE_HELPDESK"
        }
    ]

    useEffect(() => {
        if (user) {
            console.log(user, 'user')
        }
    }, [user])


    return (
        <React.Fragment>
            {user ? (
                <div className='flex flex-row min-h-screen'>
                    <div className='min-w-[200px] bg-secondary-2'>
                        <div className='flex flex-col mt-32'>
                            {nav.map((item, index) => (
                                user.permissions.includes(item.permission) || user.super_admin ? (
                                    <NavLink
                                        key={index}
                                        end
                                        to={item.route}
                                        className={({ isActive }) =>
                                            isActive ? 'flex flex-row bg-secondary-active font-bold text-white h-[65px] items-center border-l-[5px] border-l-primary px-3'
                                                :
                                                'flex flex-row  font-bold text-[#8F8F8F] h-[65px] items-center  px-3'
                                        }
                                    >
                                        <img src={item.icon} alt={item.title} className={'pr-[15px]'} />
                                        <span className='text-inherit'> {item.title}</span>
                                    </NavLink>
                                ) : null))}
                        </div>

                        <div className='mt-12 border-l-[5px] border-l-color-red h-[65px] items-center flex px-3 cursor-pointer'
                            onClick={() => logout()}
                        >
                            <img src={LogoutSVG} alt={'logout'} className={'pr-[15px]'} />
                            <span className='text-white font-bold'> Logout</span>
                        </div>
                    </div>

                    <div className='w-full flex flex-col px-3'>
                        {showAvatar ? (
                            <div className='mb-4 flex flex-row justify-end px-3 mr-3 pt-4' >
                                <div
                                    className='flex flex-row items-center'>
                                    <img src={NotificationSVG} alt='' className='mr-2' />
                                    <img src={Avatar} alt="" />
                                </div>
                            </div>
                        ) : null}

                        {children}
                    </div>
                </div>
            ) : null}

        </React.Fragment>
    )
}

export default DashboardLayout