import { Axios } from 'src/api/axios';
import { ICreateTopicDTO } from 'src/redux/topic/types';


const uploadQuestions = async (file: File) => {
  const formData = new FormData();
  formData.append('questions', file);

  const {data} = await Axios.post('/admin/quiz/replaceQuestions', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data
};

const uploadQuestionsByCountry = async (file: File,country:string) => {
  const formData = new FormData();
  formData.append('questions', file);

  const {data} = await Axios.post(`/admin/quiz/replaceQuestions/${country}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data
};

export const quiz = {
  uploadQuestions,
  uploadQuestionsByCountry
};
