import React, { useEffect, useState } from 'react'
import DashboardLayout from 'src/layout/dashboard';
import { useGetHelpdeskCategories, useGetHelpDeskMessages, useGetHelpDeskTickets, useReplyHelpdeskMessage } from '../../redux/helpdesk/hook';
import CommentSVG from 'src/assets/images/svg/comment.svg'
import BackSVG from 'src/assets/images/svg/back-icon.svg';
import ReplySVG from 'src/assets/images/svg/reply.svg';
import Tabview from 'src/components/tabview';
import LoadingBar from 'src/components/loader-bar';
import moment from 'moment';
import FormTextArea from 'src/components/form/textarea';
import { useForm } from 'react-hook-form';
import Button from 'src/components/form/button';
import DOMPurify from 'dompurify';
import FormSelect from 'src/components/form/select';
import FormInput from 'src/components/form/text-input';
import FormFileInput from '../../components/form/file-input';

function HelpDeskPage() {
    const [activeTab, setActiveTab] = useState('1');

    const [search, setSearch] = useState('');
    const [category, setCategory] = useState('');
    const [helpdeskId, setHelpdeskId] = useState('');
    const [currentTicket, setCurrentTicket] = useState<any>(null);
    const [closed, setClosed] = useState<any>(false);
    const { data: helpdeskTickets, isLoading } = useGetHelpDeskTickets(search, category, closed);
    const { data: helpdeskMessages, isLoading: isLoadingMessages } = useGetHelpDeskMessages(helpdeskId);
    const { data: helpdeskCategories } = useGetHelpdeskCategories();
    const [showReply, setShowReply] = useState(false);
    const { mutateAsync: replyHelpdeskMessage, isLoading: replyHelpdeskMessageLoading } = useReplyHelpdeskMessage();



    const ticketCaseOption: any = [
        {
            label: "Select Case",
            value: ""
        },
        {
            label: "Open",
            value: false
        },
        {
            label: "Closed",
            value: true
        },
    ]

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset
    } = useForm({
        mode: 'onChange',
    });


    useEffect(() => {
        console.log(helpdeskTickets, ' = tickets');
    }, [helpdeskTickets])

    useEffect(() => {
        console.log(helpdeskMessages, ' = messages');
    }, [helpdeskMessages])


    useEffect(() => {
        console.log(helpdeskCategories, ' = helpdesk categories');
    }, [helpdeskCategories])



    useEffect(() => {

        if (activeTab === '1') {
            setCategory('');
        }
        else if (activeTab === '2') {
            setCategory('General Inquiry');
        }
        else if (activeTab === '3') {
            setCategory('Suggestions');
        }
        else if (activeTab === '4') {
            setCategory('Payment issues');
        }
        else if (activeTab === '5') {
            setCategory('Feature Request');
        }
        else if (activeTab === '6') {
            setCategory('App Crash');
        }
        else if (activeTab === '7') {
            setCategory('Others');
        }

    }, [activeTab])

    const AllMessages = () => {

        return (
            <>
                {helpdeskTickets ? (
                    <>
                        {helpdeskTickets.map((item: any, i: number) =>
                        (
                            <>
                                <div className='flex flex-row py-3 mb-1 cursor-pointer' key={i} onClick={() => { setHelpdeskId(item._id); setCurrentTicket(item) }}>
                                    <div className='flex items-center justify-center pr-2'>
                                        {item.userId && item.userId.profile_picture ? (
                                            <img src={item.userId.profile_picture} width="30px" className='rounded-[30px] h-[30px]' alt="" />
                                        ) : null}
                                    </div>
                                    <div className='flex flex-row justify-between w-full'>
                                        <div className="flex flex-row items-center">
                                            <div className="flex flex-col">
                                                <span className='title-2-semibold'>{item.userId.first_name} {item.userId.last_name}</span>
                                                <span className='p1 font-medium'> {moment(item.createdAt).format("MMM Do YYYY, h:mm:ss a")}  </span>
                                            </div>

                                            <div className='flex flex-col ml-6'>
                                                <span className='p1 font-medium mb-2'>Ticket number: {item.ticketNumber}</span>
                                                <span className='p1 font-medium'>{item.title}</span>
                                            </div>

                                        </div>

                                        <div className='!bg-primary p-1 px-2 rounded-md h-7 flex items-center justify-center'>
                                            <span className='p2'> {item.closed ? 'Closed' : 'Open'}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className='divider' />
                            </>
                        ))}
                    </>
                ) : null}
            </>
        )
    }

    const tabs = [
        {
            title: "All",
            Content: AllMessages
        },
        {
            title: "General Inquiry",
            Content: AllMessages
        },
        {
            title: "Suggestions",
            Content: AllMessages
        },
        {
            title: "Payment issues",
            Content: AllMessages
        },
        {
            title: "Feature Request",
            Content: AllMessages
        },
        {
            title: "App Crash",
            Content: AllMessages
        },
        {
            title: "Others",
            Content: AllMessages
        },
    ];


    function submit(data: any) {

        const formData = new FormData();

        if (data.file) {
            formData.append('file', data?.file[0]);
        }

        formData.append('message', DOMPurify.sanitize(data?.message));

        let payload = {
            helpdeskId,
            data: formData
        }

        replyHelpdeskMessage(payload).then((result) => {
            reset()
            setShowReply(false);
        })
    }


    return (
        <DashboardLayout>
            <div className='flex flex-col px-3 h-full'>
                <div className='flex h-[60px] bg-secondary-2 rounded-l-[16px] flex-row items-center]'>
                    <div className='flex flex-row items-center px-4'>
                        <img src={CommentSVG} alt="" />
                        <span className='text-white font-bold ml-2'> Help Desk</span>
                    </div>
                </div>

                <LoadingBar loading={isLoading} />

                {!currentTicket ? (
                    <div className='flex flex-row w-full items-center'>

                        <input type="text"
                            onInput={(e: any) => setSearch(e.target.value)}
                            placeholder="Search"
                            value={search}
                            className="h-[40px] rounded-lg border border-black px-3 w-[50%] py-4"
                        />

                        {helpdeskCategories ? (
                            <FormSelect
                                defaultValue={category}
                                options={helpdeskCategories}
                                onChange={(selected: string) => {
                                    setCategory(selected);
                                }}
                                extraClass='mt-[20px] ml-3'
                            // type="default"
                            />
                        ) : null}

                        <FormSelect
                            defaultValue={closed}
                            options={ticketCaseOption}
                            onChange={(selected: string) => {
                                setClosed(selected);
                            }}
                            extraClass='mt-[20px] ml-3'
                        // type="default"
                        />

                    </div>
                ) : null}

                <div className='flex flex-row h-full w-full'>


                    {helpdeskMessages && currentTicket ? (
                        <div className='flex flex-col p-3 w-full'>
                            <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-row items-center mb-3 cursor-pointer' onClick={() => { setHelpdeskId(''); setCurrentTicket(null) }}> <img src={BackSVG} /> <span className='ml-3 title-2-semibold '>Go back</span>

                                </div>
                                <div>
                                    <button className='border border-black px-4 p-[12px] rounded-md flex flex-row' onClick={() => setShowReply(!showReply)}> <img src={ReplySVG} alt="" />
                                        <span className='ml-2'> {showReply ? 'Hide Reply' : 'Reply'}</span> </button>
                                </div>
                            </div>


                            <div className='flex flex-col mt-4'>
                                <div className='flex w-full justify-between'> <span className='header-2 my-2'>{currentTicket.title}</span>  <span className='title-2 my-2'>Ticket Number: #{currentTicket.ticketNumber}</span></div>

                                {showReply ? (
                                    <form onSubmit={handleSubmit(submit)}>
                                        <div className='flex flex-col'>
                                            <FormTextArea
                                                name='message'
                                                placeholder={`Reply to ${currentTicket.userId.first_name} ${currentTicket.userId.last_name}`}
                                                validator='text'
                                                register={register}
                                                required={true}
                                                readOnly={replyHelpdeskMessageLoading}
                                                error={errors.message}
                                                errorMessage={errors.message && errors.message.message}
                                            />
                                            <div className='flex flex-row items-center'>

                                                <Button
                                                    disabled={!isValid}
                                                    type='submit'
                                                    className='w-[100px] h-[50px]'
                                                    loading={replyHelpdeskMessageLoading}
                                                    color='primary'
                                                >
                                                    <span className='text-black title-2'>Send </span>
                                                </Button>

                                                <FormFileInput
                                                    register={register}
                                                    name="file"
                                                    validator='file'
                                                    required={false}
                                                    readOnly={replyHelpdeskMessageLoading}
                                                    // accept='.jpg,.png,.jpeg,.gif'
                                                    error={errors.file}
                                                    errorMessage={errors.file && errors.file.message}
                                                />


                                            </div>

                                        </div>
                                    </form>
                                ) : null}


                                {/* Messages */}
                                <div className='w-full flex flex-col pl-10 mt-3'>
                                    {helpdeskMessages.map((message: any) => (
                                        <div className='flex flex-row mt-3 mb-10'>
                                            <div className='w-[7%]'>

                                                {message.userId ? (
                                                    <>
                                                        {currentTicket.userId && currentTicket.userId.profile_picture ? (
                                                            <img src={currentTicket.userId.profile_picture} width="50px" className='rounded-[50px] h-[50px]' alt="" />
                                                        ) : null}

                                                    </>
                                                ) : null}
                                                {message.adminId ? (
                                                    <div className='h-[50px] w-[50px] !bg-primary rounded-[50px]'> </div>
                                                ) : null}

                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <div className='flex flex-row justify-between'>

                                                    <span className='title-2-semibold'> <>
                                                        {message.userId ? (
                                                            <>
                                                                {currentTicket.userId.first_name} {currentTicket.userId.last_name}</>
                                                        ) : (
                                                            <>
                                                                Admin Reply
                                                            </>
                                                        )}

                                                    </> </span>
                                                    <span className='title-2'> {moment(message.createdAt).format("MMM Do YYYY, h:mm:ss a")}</span>
                                                </div>

                                                <span className='p1 leading-[23px] mt-3 mb-5'>{message.message}</span>

                                                {message.image ? (
                                                    <div>
                                                        <img src={message.image} alt="" width={'100%'} height={'100%'} />
                                                    </div>
                                                ) : null}

                                                <hr className='divider' />
                                            </div>
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='mt-3  w-full'>
                            <Tabview tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                        </div>
                    )}

                </div>
            </div>
        </DashboardLayout>
    )
}


export default HelpDeskPage;