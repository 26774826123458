import React, { useState,useEffect } from 'react';
import FileSVG from 'src/assets/images/svg/File.svg';
import UploadSVG from 'src/assets/images/svg/uploads1.svg';
import DeleteSVG from 'src/assets/images/svg/delete.svg';
import { useUploadCsv,useUploadCsvByCountry} from 'src/redux/quiz/hook';

const UploadContainer = ({ selectedCountry }:{selectedCountry:any}) => {
  const { mutateAsync: uploadCsv} = useUploadCsvByCountry();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [width, setWidth] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setWidth((prevWidth) => {
        if (prevWidth < 100) {
          return prevWidth + 5; // Increase width by 5% (adjust as needed)
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 500); // Adjust the interval as needed
    return () => clearInterval(interval);
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    
  };

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const  handleUpload = async () => {
    // Logic to upload the file. You can use this function to trigger the upload process.
    setUploading(true);
    // Simulating file upload process
    try {
    await uploadCsv({ file: selectedFile, country: selectedCountry });
    } 
    finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };

  return (
    <div className='w-96 h-80 '>
      <div
        className={`w-full flex flex-col items-center bg-yellow-300 bg-opacity-5 rounded-3xl border border-yellow-300  ${
          selectedFile ? 'border-yellow-500' : ''
        }`}
        onDragOver={(e) => {
          e.preventDefault();
          // Add a visual indication that a file can be dropped here
          e.currentTarget.classList.add('border-yellow-500');
        }}
        onDragLeave={(e) => {
          e.currentTarget.classList.remove('border-yellow-100');
        }}
        onDrop={handleFileDrop}
      >
        <div className='flex justify-center mt-2'>
          <img src={UploadSVG} alt='' />
        </div>
        <div className="mt-[68px] text-stone-700 text-xl font-semibold font-['Poppins']">
          Drag and drop file or&nbsp;
          <label className='text-orange-400 text-xl font-semibold font-["Poppins"] underline cursor-pointer'>
            Browse
            <input
              type='file'
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </label>
        </div>

        <div className=" mt-[24px] text-stone-500 text-base font-semibold font-['Poppins'] mb-[54px]">
          Only CSV files are supported
        </div>
      </div>

      {selectedFile && (
        <div className='w-full mt-4'>
          {/* <p>Selected File: {selectedFile}</p> */}
          {uploading ? <p>Loading...</p> : <p>Success</p>}
          <div className='w-full px-4 py-3 bg-white rounded shadow  justify-between  flex'>
            <div className='w-full flex flex-col items-start gap-1'>
              <div className='flex'>
                <img src={FileSVG} alt='' />
                <div className="pl-2 opacity-80 text-neutral-600 text-sm font-medium font-['Poppins']">
                  {selectedFile.name}
                </div>
              </div>
              <div className="opacity-80 text-neutral-600 text-xs font-normal font-['Poppins']">
                {selectedFile.size}
              </div>
            </div>
            <div>
              <img
                src={DeleteSVG}
                alt=''
                className='cursor-pointer'
                onClick={handleDelete}
              />
            </div>
          </div>
          {uploading ? (
            <div>
              <p>Loading...</p>
              <div className='w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700'>
                <div
                  className={`bg-blue-600 h-1.5 rounded-full dark:bg-blue-500 w-[${width}]`}></div>
              </div>
            </div>
          ) : (
            <button
              className='mt-5 w-full h-14 p-4 bg-yellow-300 rounded-3xl justify-center items-center gap-2.5 inline-flex text-stone-700 text-base font-semibold font-["Poppins"]'
              onClick={handleUpload}
              disabled={uploading}
            >
              Upload File
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadContainer;
