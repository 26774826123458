import { useQuery } from '@tanstack/react-query';
import { dashboard } from 'src/api/dashboard';

export function useGetFeatureSummary() {
  return useQuery(['featureSummary'], dashboard.featureSummary, {
    cacheTime: 86400000, // keep in cache for a day
    staleTime: 86400000, // keep in cache for a day
  });
}

export function useGetUserAnalytics() {
  return useQuery(['user-analytics'], dashboard.usersAnalytics, {
    cacheTime: 86400000, // keep in cache for a day
    staleTime: 86400000, // keep in cache for a day
  });
}

export function useGetTopicAnalytics() {
  return useQuery(['topic-analytics'], dashboard.topicAnalytics, {
    cacheTime: 86400000, // keep in cache for a day
    staleTime: 86400000, // keep in cache for a day
  });
}

export function useGetGroupAnalytics() {
  return useQuery(['group-analytics'], dashboard.groupAnalytics, {
    cacheTime: 86400000, // keep in cache for a day
    staleTime: 86400000, // keep in cache for a day
  });
}
