import React, { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ data }: any) => {
    ChartJS.register(ArcElement, Tooltip);

    const config = useMemo(() => {
        return {
            ...data,
            ...{

            }
        }
    }, [data])


    return (
        <>
            {data ? (
                <div className='flex w-full h-full flex-row'>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <Pie data={data.data} />
                    </div>
                    <div className='flex flex-col ml-4 justify-center'>
                        {data.legends.map((item: any, index: number) => (
                            <div className='flex flex-row items-center py-1'>
                                <div
                                    className={`w-[12px] h-[12px] ${item.color}`}
                                ></div>
                                <span className='pl-1 p2'> {item.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PieChart;
